import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';

import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../menuGlobal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import api from '../../services/api';
import AmrLogo from '../../assets/AMR horizontal.png';

import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { TextField } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function DolarMilhoSoja(props) {
  const [dolarAtual, setDolarAtual] = useState();
  //const [soja, setSoja] = useState({});
  //const [milho, setMilho] = useState({});
  const [origens, setOrigens] = useState([]);
  const [origem, setOrigem] = useState(''); //variáveis a serem jogadas na api
  const [origemAg, setOrigemAg] = useState(''); //variáveis a serem jogadas na api

  const [origemsAg, setOrigemsAg] = useState([]);

  const [indicadores, setIndicadores] = useState([]);
  const [indicador, setIndicador] = useState('');
  const [indicadorAg, setIndicadorAg] = useState('');

  const [plotResult, setPlotResult] = useState([]);
  const [plotResultAg, setPlotResultAg] = useState([]);

  useEffect(() => {
    if (props.token?.token === undefined) {
      //   alert("Você não está logado!");
      props.history.push({ pathname: '/' });
    }
    async function fetchDolarSojaMilho() {
      const response_dolar = await api.get('/plotaDolar');
      setDolarAtual(response_dolar.data);

      // const response_soja = await api.get("/returnSoja");
      //setSoja(response_soja.data);

      // const response_milho = await api.get("/returnMilho");
      // setMilho(response_milho.data);

      const response = await api.get('/returnOrigensIfag');
      setOrigens(response.data);

      const response_ind = await api.get('/returnIndicadoresIfag');
      setIndicadores(response_ind.data);

      const response_ag = await api.get('/returnCidadeIndexAgRural');
      setOrigemsAg(response_ag.data);
    }
    fetchDolarSojaMilho();
  }, [props.history, props.token]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function retornaIfag() {
    const response_ifag = await api.get('/returnIfag', {
      params: {
        cidade_estado: origem,
        indicador: indicador,
      },
    });
    setPlotResult(response_ifag.data);
  }

  async function retornaAgRural() {
    const response_ag = await api.get('/returnAgRural', {
      params: {
        cidade: origemAg,
        coisa: indicadorAg,
      },
    });
    console.log(response_ag.data);
    setPlotResultAg(response_ag.data);
  }

  const data = dolarAtual;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Histórico de Commodities
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h1> Histórico Bacen</h1>
          </div>
          <div>
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="x" />
              <YAxis dataKey="R$" domain={[3, 7]} />
              <Tooltip coordinate={'R$'} />
              <Line type="monotone" dataKey="R$" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </div>
          {/* <div>
            <h1>Histórico Soja</h1>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={origens}
                  getOptionLabel={(option) => option.cidade}
                  style={{ width: 300 }}
                  onInputChange={(e, v) => setOrigem(v)}
                  renderInput={(params) => <TextField {...params} label="Selecionar Cidade" variant="outlined" />}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={indicadores}
                  getOptionLabel={(option) => option.indicador}
                  style={{ width: 300 }}
                  onInputChange={(e, v) => setIndicador(v)}
                  renderInput={(params) => <TextField {...params} label="Selecionar Indicador" variant="outlined" />}
                />
              </div>
              <button
                style={{
                  marginTop: 20,
                  height: 50,
                  width: 250,
                  borderRadius: 20,
                  borderStyle: 'solid',
                  background: 'linear-gradient(to right, #a9d046, #d3e05c)',
                }}
                onClick={() => retornaIfag()}
              >
                {' '}
                <p
                  style={{
                    marginTop: 13,
                    fontStyle: 'bold',
                    color: 'white',
                    fontSize: 16,
                  }}
                >
                  Buscar Histórico
                </p>
              </button>
            </div>
            <div style={{ marginTop: 40 }}>
              <LineChart
                width={500}
                height={300}
                data={plotResult}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="x" />
                <YAxis dataKey="valor" domain={[0, 300]} />
                <Tooltip coordinate={'valor'} />
                <Line type="monotone" dataKey="valor" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </div>
          </div>*/}
          {/* <div>
            <h1>Milho</h1>
            <table>
              <thead>
                <tr>
                  <th>valor</th>
                  <th>varDia</th>
                  <th>varMes</th>
                  <th>valorUs</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(milho).map((item) => (
                  <tr key={item}>
                    <td> {milho[item].valor}</td>
                    <td> {milho[item].varDia}</td>
                    <td> {milho[item].varMes}</td>
                    <td> {milho[item].valorUs}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
          <div>
            <h1>Histórico Soja Milho Disponível</h1>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={origemsAg}
                  getOptionLabel={(option) => option.praca}
                  style={{ width: 300 }}
                  onInputChange={(e, v) => setOrigemAg(v)}
                  renderInput={(params) => <TextField {...params} label="Selecionar Cidade" variant="outlined" />}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={[{ name: 'milho' }, { name: 'soja' }]}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 300 }}
                  onInputChange={(e, v) => setIndicadorAg(v)}
                  renderInput={(params) => <TextField {...params} label="Selecionar Indicador" variant="outlined" />}
                />
              </div>
              <button
                style={{
                  marginTop: 20,
                  height: 50,
                  width: 250,
                  borderRadius: 20,
                  borderStyle: 'solid',
                  background: 'linear-gradient(to right, #a9d046, #d3e05c)',
                }}
                onClick={() => retornaAgRural()}
              >
                <p
                  style={{
                    marginTop: 13,
                    fontStyle: 'bold',
                    color: 'white',
                    fontSize: 16,
                  }}
                >
                  Buscar Histórico
                </p>
              </button>
            </div>
            <div style={{ marginTop: 40 }}>
              <LineChart
                width={500}
                height={300}
                data={plotResultAg}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="x" />
                <YAxis dataKey="valor" domain={[50, 200]} />
                <Tooltip coordinate={'valor'} />
                <Line type="monotone" dataKey="valor" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </div>
          </div>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DolarMilhoSoja);
