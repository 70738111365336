import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';

import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import api from '../../../services/api';
import exportFromJSON from 'export-from-json'
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import AmrLogo from '../../../assets/AMR horizontal.png';
import isSuper from '../../../utils/utils';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function Basis(props) {
  const [origens, setOrigens] = useState([]);
  const [origem, setOrigem] = useState(''); //variáveis a serem jogadas na api
  const [fretes, setFretes] = useState('');
  const [polyBasis,setPolyBasis] = useState('')
  const [basisStr,setBasisStr] = useState('')
  
  useEffect(() => {
    if (props.token?.token === undefined) {
      //   alert("Você não está logado!");
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      const response = await api.get('/retornaIndexBasis');
      setOrigens(response.data);
    }
    fetchMyAPI();
  }, [props.history, props.token, props.user.user]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function getBasis() {
    try {
      const user = JSON.parse(window.localStorage.getItem('user_data'));
      await api.post('/controletrafego', { user: user.ID, path: 'Basis Soja' });
    } catch (error) {
      console.log('controle de trafego não registrado');
    }

    const response = await api.get('/retornaBasis', {
      params: {
        cidade: origem,
      },
    });
    setFretes(response.data);

    const response_basis_str = await api.get('/retornaBasisStr', {
      params: {
        cidade: origem,
      },
    });
    setBasisStr(response_basis_str.data);

    const response_poly = await api.get('/retornar_poly_fit_cidade', {
      params: {  cidade: origem },
    });
    setPolyBasis('')
    setPolyBasis(response_poly.data)
  }
  const convertBasis = () => {
    const data = basisStr
    const fileName = 'Histórico Basis  '+origem
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Basis
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h1> Basis</h1>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Autocomplete
              id="combo-box-demo"
              options={origens}
              getOptionLabel={(option) => option.cidade}
              style={{ width: 300 }}
              onInputChange={(e, v) => setOrigem(v)}
              renderInput={(params) => <TextField {...params} label="Origem" variant="outlined" />}
            />

            <button
              style={{
                marginTop: 20,
                height: 50,
                width: 250,
                borderRadius: 20,
                borderStyle: 'solid',
                background: 'linear-gradient(to right, #a9d046, #d3e05c)',
              }}
              onClick={() => getBasis()}
            >
              {' '}
              <p
                style={{
                  marginTop: 13,
                  fontStyle: 'bold',
                  color: 'white',
                  fontSize: 16,
                }}
              >
                Retornar Basis{' '}
              </p>
            </button>
          </div>
          {((isSuper)&&(basisStr !== '') )? <button onClick={() => convertBasis()}> Baixar Histórico Basis</button>: null}
          {fretes !== '' ? (
            <div>
              <div>
                <LineChart
                  width={500}
                  height={300}
                  data={fretes}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="data" />
                  <YAxis dataKey="basis" />
                  <Tooltip coordinate={'basis'} />
                  <Line type="monotone" dataKey="basis" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
              </div>
              <div>
                { polyBasis !== 'NOK'?  <img src={`data:image/png;base64,${polyBasis}`} /> : <p> *Linha de tendência ainda não disponível</p>}
             
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <table
                  style={{
                    border: '1px solid lightgray',
                    width: 900,
                    borderCollapse: 'collapse',
                    borderSpacing: 0,
                    borderRadius: 20,
                    padding: 10,
                  }}
                >
                  <thead>
                    <tr style={{ background: 'lightgray', height: 50 }}>
                      <td style={{ width: 200, textAlign: 'center' }}>Data</td>
                      <td style={{ width: 200, textAlign: 'center' }}>Futuro</td>
                      <td style={{ width: 200, textAlign: 'center' }}>Fisico</td>
                      <td style={{ width: 200, textAlign: 'center' }}>CME ($/Bushel)</td>
                      <td style={{ width: 200, textAlign: 'center' }}>cambio</td>
                      <td style={{ width: 200, textAlign: 'center' }}>Basis ($/Bushel)</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(fretes).map((index, value) => (
                      <tr
                        style={{
                          background: index % 2 !== 0 ? 'lightgray' : 'white',
                          height: 50,
                        }}
                      >
                        <th style={{ width: 200, textAlign: 'center' }}>{fretes[index]['data']}</th>
                        <th style={{ width: 200, textAlign: 'center' }}>{fretes[index]['futuro']}</th>

                        <th style={{ width: 200, textAlign: 'center' }}>{fretes[index]['fisico']}</th>

                        <th style={{ width: 200, textAlign: 'center' }}>{fretes[index]['cme_valor']}</th>

                        <th style={{ width: 200, textAlign: 'center' }}>{fretes[index]['cambio']}</th>

                        <th style={{ width: 200, textAlign: 'center' }}>{fretes[index]['basis']}</th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div></div>
          )}
         
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  Spaces: {
    width: '30%',
  },

  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    borderBottom: 'none',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Basis);
