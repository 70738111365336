import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';

import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { TextField } from '@material-ui/core';
import api from '../../../services/api';
import AmrLogo from '../../../assets/AMR horizontal.png';
import isSuper from '../../../utils/utils.js'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function ConfigFretes(props) {
  //Cheio de comment pois foram removidos os fretes da pagina devido a alterações no site da fretebras
  // const [origens, setOrigens] = useState([]);
  // const [destinos, setDestinos] = useState([]);
  // const [origem, setOrigem] = useState(""); //variáveis a serem jogadas na api
  // const [destino, setDestino] = useState("");
  // const [rotas, setRotas] = useState("");
  // const [freteFertilizantes, setFreteFertilizantes] = useState(0);
  // const [freteSoja, setFreteSoja] = useState(0);
  // const [valorSoja, setValorSoja] = useState(0);
  const [materiasFiltro, setMateriasFiltro] = useState('');
  const [materiasPorto, setMateriasPorto] = useState('');

  const [rem, setRem] = useState([]);
  const [materias, setMaterias] = useState('');

  useEffect(() => {
    if (((props.token?.token === undefined) & (window.localStorage.getItem('token') === null) )|(isSuper() !== true)) {
      props.history.push({ pathname: '/' });
    }

    async function fetchMyAPI() {
      // const response = await api.get("/returnPortos");
      // setOrigens(response.data);
      const response_materias = await api.get('/returnIndiceMaterias');
      setMaterias(response_materias.data);
      const response_materias_troca = await api.get('/returnIndiceMateriasTroca');
      setMateriasFiltro(response_materias_troca.data);
      const response_materias_porto = await api.get('/returnIndiceMateriasPorto');
      setMateriasPorto(response_materias_porto.data);
      // const response_destino = await api.get("/returnDestinos");
      // setDestinos(response_destino.data);
      // const response_rotas = await api.get("/retornaRotasConfigFrete");
      // setRotas(response_rotas.data);
      // console.log(response_rotas.data);
    }
    fetchMyAPI();
  }, [props.history, props.token, props.user]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function atualizaMaterias() {
    let auxRem = [];
    rem.forEach((index) => (auxRem = [...auxRem, index.abrev]));
    const response = await api.get('/adicionaMateriaRelacao', {
      params: {
        rem: auxRem.toString(),
      },
    });
    if (response.data === 'OK') {
      alert('materias adicionada com sucesso!');
      window.location.reload();
    } else if (response.data === 'NOK') {
      alert('Erro ao adicionar materias!');
    }
  }

  async function atualizaMateriasPorto() {
    let auxRem = [];
    rem.forEach((index) => (auxRem = [...auxRem, index.abrev]));
    const response = await api.get('/adicionaMateriaPorto', {
      params: {
        rem: auxRem.toString(),
      },
    });
    if (response.data === 'OK') {
      alert('materias adicionada com sucesso!');
      window.location.reload();
    } else if (response.data === 'NOK') {
      alert('Erro ao adicionar materias!');
    }
  }
  // async function saveConfigFretes() {
  //   const response = await api.get("/adicionarOrigemDestinoFrete", {
  //     params: {
  //       origem: origem,
  //       destino: destino,
  //       freteFertilizantes: freteFertilizantes,
  //       freteSoja: freteSoja,
  //       valorSoja: valorSoja,
  //     },
  //   });
  //   if (response.data === "OK") {
  //     alert("Rota adicionada com sucesso!");
  //     window.location.reload();
  //   } else if (response.data === "NOK") {
  //     alert("Erro ao adicionar Rota!");
  //   }
  // }

  // async function removeRotas(index) {
  //   const response = await api.get("/removerFretes", {
  //     params: {
  //       rota: rotas[index],
  //     },
  //   });
  //   if (response.data === "OK") {
  //     alert("Rota removida com sucesso!");
  //     window.location.reload();
  //   }
  // }
  async function removeMateria(index) {
    const response = await api.get('/removeMateriaTroca', {
      params: {
        materia: index,
      },
    });
    if (response.data === 'OK') {
      alert('Matéria removida com sucesso!');
      window.location.reload();
    } else {
      alert('Falha ao remover matéria!');
      window.location.reload();
    }
  }

  async function removeMateriaPorto(index) {
    const response = await api.get('/removeMateriaPorto', {
      params: {
        materia: index,
      },
    });
    if (response.data === 'OK') {
      alert('Matéria removida com sucesso!');
      window.location.reload();
    } else {
      alert('Falha ao remover matéria!');
      window.location.reload();
    }
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Configurações de Relação de Troca
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h1> Configurações de matérias para relaçao de troca</h1>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Autocomplete
                multiple
                id="multiple-limit-tags"
                options={materias}
                getOptionLabel={(option) => option.abrev}
                onChange={(e, v) => setRem(v)}
                style={{ width: 350, marginTop: 10 }}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Matérias para apresentar na relação de troca" placeholder="Matérias para apresentar na relação de troca" />}
              />
            </div>
            <button onClick={() => atualizaMaterias()}>atualizaMaterias</button>
            <div>
              {Object.keys(materiasFiltro).map((index, value) => (
                <div style={{ display: 'flex', flexDirection: 'row' }} key={value}>
                  <p style={{ width: 120 }}> {materiasFiltro[value].abrev}</p>
                  <button onClick={() => removeMateria(materiasFiltro[value].abrev)}>Remover</button>
                </div>
              ))}
            </div>
          </div>
          {/* <div style={{ display: "flex", flexDirection: "column" }}>
            <h1> Configurações de fretes para relação de troca</h1>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Autocomplete
                id="combo-box-demo"
                options={origens}
                getOptionLabel={(option) => option.porto}
                style={{ width: 300 }}
                onInputChange={(e, v) => setOrigem(v)}
                renderInput={(params) => (
                  <TextField {...params} label="Porto" variant="outlined" />
                )}
              />
              <Autocomplete
                id="combo-box-demo"
                options={destinos}
                getOptionLabel={(option) => option.destinoEstado}
                style={{ width: 300 }}
                onInputChange={(e, v) => setDestino(v)}
                renderInput={(params) => (
                  <TextField {...params} label="Destino" variant="outlined" />
                )}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                {" "}
                <TextField
                  onChange={(e) => setFreteFertilizantes(e.target.value)}
                  label="Valor Frete Fertilizantes"
                />
                <TextField
                  onChange={(e) => setFreteSoja(e.target.value)}
                  label="Valor Frete Soja"
                />
                <TextField
                  onChange={(e) => setValorSoja(e.target.value)}
                  label="Valore Soja"
                />
              </div>
            </div>
            <div>
              <button onClick={() => saveConfigFretes()}>
                {" "}
                Adicionar Rota{" "}
              </button>
            </div>
            {rotas !== "" ? (
              <div style={{ marginTop: 100 }}>
                {Object.keys(rotas).map((item, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <p style={{ width: 100 }}>{rotas[item].porto}</p>

                    <p style={{ width: 100 }}>{rotas[item].cidade}</p>
                    <p style={{ width: 100 }}>
                      {rotas[item].freteFertilizantes}
                    </p>
                    <p style={{ width: 100 }}>{rotas[item].freteSoja}</p>
                    <p style={{ width: 100 }}>{rotas[item].valorSoja}</p>
                    <button
                      style={{ width: 150 }}
                      onClick={() => removeRotas(index)}
                    >
                      {" "}
                      Remover Rota
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              console.log(rotas)
            )}
          </div> */}
          <div>
            <h1> Configurações de matérias para precificaçao de porto</h1>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Autocomplete
                multiple
                id="multiple-limit-tags"
                options={materias}
                getOptionLabel={(option) => option.abrev}
                onChange={(e, v) => setRem(v)}
                style={{ width: 350, marginTop: 10 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Matérias para apresentar na precificaçao de porto" placeholder="Matérias para apresentar na precificaçao de porto" />
                )}
              />
            </div>
            <button onClick={() => atualizaMateriasPorto()}>atualizaMaterias</button>
            <div>
              {Object.keys(materiasPorto).map((index, value) => (
                <div style={{ display: 'flex', flexDirection: 'row' }} key={value}>
                  <p style={{ width: 120 }}> {materiasPorto[value].abrev}</p>
                  <button onClick={() => removeMateriaPorto(materiasPorto[value].abrev)}>Remover</button>
                </div>
              ))}
            </div>
          </div>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigFretes);
