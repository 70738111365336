/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import api from '../../../services/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import AmrLogo from '../../../assets/AMR horizontal.png';
import isSuper from '../../../utils/utils.js'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function ConfigPortos(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [portos, setPortos] = useState();
  const [toUpdatePortos, setToUpdatePortos] = useState([]);
  //const [toUpdateConfigs, setToUpdateConfigs] = useState([]);

  //const [configs, setConfigs] = useState();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (((props.token?.token === undefined) & (window.localStorage.getItem('token') === null) )|(isSuper() !== true)) {
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      const response = await api.get('/returnPortosADM');
      setPortos(response.data);
      // const response_config = await api.get("/returnPortosConfigADM");
      // setConfigs(response_config.data);
    }
    fetchMyAPI();
  }, []);

  const handleUpdatePortos = (item, item_, target) => {
    let aux = toUpdatePortos;
    aux[item_ + '-' + portos[item]['ID_porto'].toString()] = target;
    setToUpdatePortos(aux);
  };

  // const handleUpdateConfigs = (item, item_, target) => {
  //   let aux = toUpdateConfigs;
  //   aux[item_] = target;
  //   setToUpdateConfigs(aux);
  // };

  async function handlePortosChange() {
    let aux = '';
    Object.keys(toUpdatePortos).forEach((item) => (aux = aux + item + '#' + toUpdatePortos[item] + ';'));

    const response = await api.get('/modificaPortosADM', {
      params: { toUpdate: aux },
    });
    if (response.data === 'NOK') {
      alert('Erro ao atualizar portos!');
      window.location.reload();
    } else {
      alert('Portos alterados com sucesso!');
      window.location.reload();
    }
  }

  // async function handleConfigChange() {
  //   let aux = "";

  //   Object.keys(toUpdateConfigs).forEach(
  //     (item) => (aux = aux + item + "#" + toUpdateConfigs[item] + ";")
  //   );

  //   const response = await api.get("/modificaPortosConfigADM", {
  //     params: { toUpdate: aux },
  //   });
  //   if (response.data === "NOK") {
  //     alert("Erro ao atualizar configurações gerais!");
  //     window.location.reload();
  //   } else {
  //     alert("Configurações gerais alteradas com sucesso!");
  //     window.location.reload();
  //   }
  // }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Configuração de Portos
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h1>Configurações portos</h1>
          </div>
          <div>
            {portos !== undefined ? (
              <table style={{ border: '1px solid #FF0000' }}>
                <thead>
                  <tr style={{ border: '1px solid #FF0000' }}>
                    <th style={{ border: '1px solid #FF0000' }}>Index</th>
                    <th style={{ border: '1px solid #FF0000' }}>Porto</th>

                    <th style={{ border: '1px solid #FF0000', width: 50 }}>desestiva R$</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>demurrage USD</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Taxa de Importação %</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Bag R$</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Industrialização R$</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Insurance %</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Inspection $</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>AFRMM %</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Losses %</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Custom Expenses $</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>ICMS %</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Vira R$</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(portos).map((item, index) => (
                    <tr key={item} style={{ border: '1px solid #FF0000' }}>
                      {Object.keys(portos[item]).map((item_, index_) =>
                        item_ === 'porto' || item_ === 'ID_porto' ? (
                          <td key={item_} style={{ border: '1px solid #FF0000' }}>
                            {portos[item][item_]}
                          </td>
                        ) : (
                          <td>
                            {console.log(item_)}
                            <TextField onChange={(e) => handleUpdatePortos(item, item_, e.target.value)} style={{ border: '1px solid #FF0000' }} label={portos[item][item_]} />
                          </td>
                        )
                      )}

                      {/* {Object.keys(portos[item]).map((item_, index_) =>
                        item_ === "desestiva"  ? (
                          <td>
                            <TextField
                              onChange={(e) =>
                                handleUpdatePortos(item, item_, e.target.value)
                              }
                              style={{ border: "1px solid #FF0000" }}
                              label={portos[item][item_]}
                            />
                          </td>
                        ) : (
                          console.log(1)
                        )
                      )}
                      {Object.keys(portos[item]).map((item_, index_) =>
                        item_ === "demurrage"  ? (
                          <td>
                            <TextField
                              onChange={(e) =>
                                handleUpdatePortos(item, item_, e.target.value)
                              }
                              style={{ border: "1px solid #FF0000" }}
                              label={portos[item][item_]}
                            />
                          </td>
                        ) : (
                          console.log(1)
                        ) 
                      )}
                      {Object.keys(portos[item]).map((item_, index_) =>
                        item_ === "importation_tax"  ? (
                          <td>
                            <TextField
                              onChange={(e) =>
                                handleUpdatePortos(item, item_, e.target.value)
                              }
                              style={{ border: "1px solid #FF0000" }}
                              label={portos[item][item_]}
                            />
                          </td>
                        ) : (
                          console.log(1)
                        )
                      )} */}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              console.log(portos)
            )}
            <button onClick={() => handlePortosChange()}>Alterar Configurações de porto</button>
            {/* <div>
              <h1> Configurações Gerais</h1>
            </div>
            {configs !== undefined ? (
              <table style={{ border: "1px solid #FF0000" }}>
                <thead>
                  <tr style={{ border: "1px solid #FF0000" }}>
                    <th style={{ border: "1px solid #FF0000", width: 100 }}>
                      Bag R$
                    </th>
                    <th style={{ border: "1px solid #FF0000", width: 100 }}>
                      Industrialization R$
                    </th>

                    <th style={{ border: "1px solid #FF0000", width: 100 }}>
                      Vira R$
                    </th>
                    <th style={{ border: "1px solid #FF0000", width: 100 }}>
                      Insurance %
                    </th>
                    <th style={{ border: "1px solid #FF0000", width: 100 }}>
                      Inspection $
                    </th>

                    <th style={{ border: "1px solid #FF0000", width: 100 }}>
                      Importation Tax $
                    </th>
                    <th style={{ border: "1px solid #FF0000", width: 100 }}>
                      AFRMM %
                    </th>
                    <th style={{ border: "1px solid #FF0000", width: 100 }}>
                      Losses %
                    </th>
                    <th style={{ border: "1px solid #FF0000", width: 100 }}>
                      Customhouse Exp $
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(configs).map((item, index) => (
                    <tr key={item} style={{ border: "1px solid #FF0000" }}>
                      {Object.keys(configs[item]).map((item_, index_) => (
                        <td>
                          {index_ !== 9 ? (
                            <TextField
                              key={item_}
                              onChange={(e) =>
                                handleUpdateConfigs(item, item_, e.target.value)
                              }
                              style={{ border: "1px solid #FF0000" }}
                              label={configs[item][item_]}
                            />
                          ) : (
                            console.log(index_)
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              console.log(configs)
            )}
            <button onClick={() => handleConfigChange()}>
              Alterar Configurações gerais
            </button>*/}
          </div>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigPortos);
