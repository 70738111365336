/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import api from '../../../services/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import AmrLogo from '../../../assets/AMR horizontal.png';
import isSuper from '../../../utils/utils.js'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function ConfigFormulasPadrao(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [compat, setCompat] = useState();
  const [toUpdateComp, setToUpdateComp] = useState([]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (((props.token?.token === undefined) & (window.localStorage.getItem('token') === null) )|(isSuper() !== true)) {
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      const response = await api.get('/returnFechamentoPadraoADM');
      setCompat(response.data);
      console.log(response.data);
    }
    fetchMyAPI();
  }, []);

  const handleUpdate = (item, item_, target) => {
    console.log(item, item_, target);
    let aux = toUpdateComp;
    aux[compat[item]['formulas'] + '*' + item_] = target;
    setToUpdateComp(aux);
  };

  async function handlePriceChange() {
    let auxComp = '';

    Object.keys(toUpdateComp).forEach((item) => (auxComp = auxComp + item + '#' + toUpdateComp[item] + ';'));
    const response = await api.get('/modificaFechamentoPadraoADM', {
      params: {
        toUpdateComp: auxComp,
      },
    });
    if (response.data === 'NOK') {
      alert('Erro ao atualizar FechamentoPadrao!');
    } else {
      alert('FechamentoPadrao alterados com sucesso!' + response.data);
      window.location.reload();
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Configurações de Fechamentos Padrão
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h1>Config fechamento padrão</h1>
          </div>
          <div>
            {compat !== undefined ? (
              <table
                style={{
                  borderCollapse: 'collapse',
                  borderSpacing: 0,
                }}
              >
                <thead style={{ height: 50 }}>
                  <tr>
                    {Object.keys(compat[0]).map((item_, index) => (
                      <td style={{ width: 200, textAlign: 'center' }}>{item_}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(compat).map((item, index) => (
                    <tr
                      key={item}
                      style={{
                        background: index % 2 === 0 ? 'lightgray' : 'white',
                        height: 50,
                      }}
                    >
                      {Object.keys(compat[item]).map((item_, index_) => (
                        <td key={item_} style={{ border: '1px solid lightgray', height: 60 }}>
                          {item_ === 'formulas' || item_ === 'FECHAMENTO' ? (
                            compat[item][[item_]]
                          ) : (
                            <div>
                              <TextField
                                onChange={(e) => handleUpdate(item, item_, e.target.value)}
                                style={{
                                  width: 60,
                                }}
                                label={compat[item][[item_]]}
                              />
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              console.log(compat)
            )}
            <button onClick={() => handlePriceChange()}> Alterar Fórmulas </button>
          </div>

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigFormulasPadrao);
