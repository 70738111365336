/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../menuGlobal';
import api from '../../services/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AmrLogo from '../../assets/AMR horizontal.png';
import isSuper from '../../utils/utils.js'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function RegisteredUsers(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [users, setUsers] = useState({});
  const [nomes, setNomes] = useState([]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (((props.token?.token === undefined) & (window.localStorage.getItem('token') === null) )|(isSuper() !== true)) {
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      const response = await api.get('/cadastrados');
      setUsers(response.data);
      var aux = [];
      console.log(users);
      Object.keys(response.data).forEach((index) => (aux = [...aux, response.data[index]]));
      setNomes(aux);
    }
    fetchMyAPI();
    // if (props.token?.token === undefined) {
    //   alert("Você não está logado!");
    //   props.history.push({ pathname: "/" });
    // }
  }, []);

  async function deactivate(item) {
    console.log(item);
    if (item.cellphone == null) {
      item.cellphone = '';
    }

    const params = {
      ID: item.ID,
    };

    const response = await api.get('/inactiveUser', { params });
    console.log(response);
    if (response.data === 'NOK') {
      alert('Erro ao desativar usuário!');
      window.location.reload();
    } else {
      alert('Usuário desativado com sucesso!');
      window.location.reload();
    }
  }

  async function active(item) {
    console.log(item);
    if (item.cellphone == null) {
      item.cellphone = '';
    }

    const params = {
      ID: item.ID,
    };

    const response = await api.get('/activeUser', { params });
    console.log(response);
    if (response.data === 'NOK') {
      alert('Erro ao ativar usuário!');
      window.location.reload();
    } else {
      alert('Usuário ativado com sucesso!');
      window.location.reload();
    }
  }
  async function deletUser(item) {
    console.log(item);
    if (item.cellphone == null) {
      item.cellphone = '';
    }

    const params = {
      ID: item.ID,
    };

    const response = await api.get('/deletUser', { params });
    console.log(response);
    if (response.data === 'NOK') {
      alert('Erro ao deletar usuário!');
      window.location.reload();
    } else {
      alert('Usuário deletado com sucesso!');
      window.location.reload();
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Usuários Registrados
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h1>Usuários cadastrados</h1>
          </div>
          {console.log(nomes)}
          <table>
            <thead>
              <tr>
                <th>Login</th>
                <th>Senha</th>
                <th>Email</th>
                <th>Celular</th>
                <th>ativo</th>
              </tr>
            </thead>
            <tbody>
              {nomes.map((item, index) => (
                <tr key={index}>
                  <td>{item.login}</td>
                  <td>{item.senha}</td>
                  <td>{item.email}</td>
                  <td>{item.cellphone}</td>
                  <td>{item.active}</td>
                  <td>
                    <button onClick={() => active(item)}>Ativar Usuário</button>
                  </td>
                  <td>
                    <button onClick={() => deactivate(item)}>Desativar Usuário</button>
                    <button onClick={() => deletUser(item)}>Deletar Usuário</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredUsers);
