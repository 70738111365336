import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import api from '../../../services/api';
import AmrLogo from '../../../assets/AMR horizontal.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function HistoricoRelTroca(props) {
  const [imageUrMilho, setImageUrMilho] = useState('');
  const [imageKCLMilho, setImageKCLMilho] = useState('');
  const [imageMAPMilho, setImageMAPMilho] = useState('');
  const [imageSSPSoja, setImageSSPSoja] = useState('');
  const [imageKCLSoja, setImageKCLSoja] = useState('');
  const [imageMAPSoja, setImageMAPSoja] = useState('');

  const [imageUrMilhobar, setImageUrMilhobar] = useState('');
  const [imageKCLMilhobar, setImageKCLMilhobar] = useState('');
  const [imageMAPMilhobar, setImageMAPMilhobar] = useState('');
  const [imageSSPSojabar, setImageSSPSojabar] = useState('');
  const [imageKCLSojabar, setImageKCLSojabar] = useState('');
  const [imageMAPSojabar, setImageMAPSojabar] = useState('');
  const [lastUpdate, setLastUpdate] = useState('');

  const [dados, setDados] = useState(false);

  useEffect(() => {
    if (props.token?.token === undefined) {
      //   alert("Você não está logado!");
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      try {
        const user = JSON.parse(window.localStorage.getItem('user_data'));
        await api.post('/controletrafego', { user: user.ID, path: 'Histórico de Relacao de troca' });
      } catch (error) {
        console.log('controle de trafego não registrado');
      }

      const response = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'UR', tipo: 'milho', dados, bar: 'false', adm: 'false' },
      });
      setImageUrMilho(response.data);
      const responseKCL = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'KCL', tipo: 'milho', dados, bar: 'false', adm: 'false' },
      });
      setImageKCLMilho(responseKCL.data);
      const responseMAP = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'MAP 52', tipo: 'milho', dados, bar: 'false', adm: 'false' },
      });
      setImageMAPMilho(responseMAP.data);
      const responseMAPSo = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'MAP 52', tipo: 'soja', dados, bar: 'false', adm: 'false' },
      });
      setImageMAPSoja(responseMAPSo.data);
      const responseKCLSo = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'KCL', tipo: 'soja', dados, bar: 'false', adm: 'false' },
      });
      setImageKCLSoja(responseKCLSo.data);
      const responseSSP = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'SSP 21', tipo: 'soja', dados, bar: 'false', adm: 'false' },
      });
      setImageSSPSoja(responseSSP.data);

      const responsebar = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'UR', tipo: 'milho', dados, bar: 'true', adm: 'false' },
      });
      setImageUrMilhobar(responsebar.data);
      const responseKCLbar = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'KCL', tipo: 'milho', dados, bar: 'true', adm: 'false' },
      });
      setImageKCLMilhobar(responseKCLbar.data);
      const responseMAPbar = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'MAP 52', tipo: 'milho', dados, bar: 'true', adm: 'false' },
      });
      setImageMAPMilhobar(responseMAPbar.data);
      const responseMAPSobar = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'MAP 52', tipo: 'soja', dados, bar: 'true', adm: 'false' },
      });
      setImageMAPSojabar(responseMAPSobar.data);
      const responseKCLSobar = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'KCL', tipo: 'soja', dados, bar: 'true', adm: 'false' },
      });
      setImageKCLSojabar(responseKCLSobar.data);
      const responseSSPbar = await api.get('/returnRelacaoDeTrocaTeste', {
        params: { materia: 'SSP 21', tipo: 'soja', dados, bar: 'true', adm: 'false' },
      });
      setImageSSPSojabar(responseSSPbar.data);

      const respose_update = await api.get('/returnLastUpdateMaterias');
      setLastUpdate(respose_update.data);
    }
    fetchMyAPI();
  }, [dados, props.history, props.token]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Histórico de Relacao de troca
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h3>Último Update no valor das máterias primas :{lastUpdate}</h3>

            <div>
              <h4> Soja</h4>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  {imageKCLSoja !== '' ? (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img src={`data:image/png;base64,${imageKCLSoja}`} />
                  ) : (
                    <CircularProgress style={{ color: 'green' }} />
                  )}
                </div>
                <div>
                  {imageKCLSojabar !== '' ? (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img src={`data:image/png;base64,${imageKCLSojabar}`} />
                  ) : (
                    <CircularProgress style={{ color: 'green' }} />
                  )}
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    {imageMAPSoja !== '' ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img src={`data:image/png;base64,${imageMAPSoja}`} />
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                  <div>
                    {imageMAPSojabar !== '' ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img src={`data:image/png;base64,${imageMAPSojabar}`} />
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    {imageSSPSoja !== '' ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img src={`data:image/png;base64,${imageSSPSoja}`} />
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                  <div>
                    {imageSSPSojabar !== '' ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img src={`data:image/png;base64,${imageSSPSojabar}`} />
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h4> Milho</h4>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    {imageUrMilho !== '' ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img src={`data:image/png;base64,${imageUrMilho}`} />
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                  <div>
                    {imageUrMilhobar !== '' ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img src={`data:image/png;base64,${imageUrMilhobar}`} />
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    {imageMAPMilho !== '' ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img src={`data:image/png;base64,${imageMAPMilho}`} />
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                  <div>
                    {imageMAPMilhobar !== '' ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img src={`data:image/png;base64,${imageMAPMilhobar}`} />
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    {imageKCLMilho !== '' ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img src={`data:image/png;base64,${imageKCLMilho}`} />
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                  <div>
                    {imageKCLMilhobar !== '' ? (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img src={`data:image/png;base64,${imageKCLMilhobar}`} />
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoricoRelTroca);
