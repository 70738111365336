import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';

import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from 'react-loader-spinner';
import { usePromiseTracker } from 'react-promise-tracker';
import Checkbox from '@material-ui/core/Checkbox';

import { trackPromise } from 'react-promise-tracker';
import { TextField } from '@material-ui/core';
import api from '../../../services/api';

import { AiFillCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';

import AmrLogo from '../../../assets/AMR horizontal.png';

// eslint-disable-next-line no-undef
// const isSafari =
//   /constructor/i.test(window.HTMLElement) ||
//   (function (p) {
//     return p.toString() === '[object SafariRemoteNotification]';
//   })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

const isSafari = false;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: '100%',
          height: '100',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    )
  );
};

const drawerWidth = 260;

function DevFormulas(props) {
  const [formulas, setFormulas] = useState('');
  const [formulas_default, setFormulas_default] = useState('');
  const [materias, setMaterias] = useState('');
  const [micronutri, setMicronutri] = useState('');
  const [nitro, setNitro] = useState(0);
  const [p2O5, setP2O5] = useState(0);
  const [k2O, setk2O] = useState(0);
  const [dolarHora, setDolarHora] = useState(0);
  const [dolarAtual, setDolarAtual] = useState();
  const [portos, setPortos] = useState([]);
  const [porto, setPorto] = useState('');
  const [frete, setFrete] = useState(0);
  const [pref, setPref] = useState([]);
  const [rem, setRem] = useState([]);
  const [micro, setMicro] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [inibidor, setInibidor] = useState(false);
  const [lastUpdate, setLastUpdate] = useState('');

  const [dataVencimento, setDataVencimento] = useState('30/03/2023');
  const [valorJuroUSD, setValorJuroUSD] = useState(1);
  const [valorJuroBR, setValorJuroBR] = useState(1.7);

  useEffect(() => {
    if (props.token?.token === undefined) {
      //   alert("Você não está logado!");
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      const response_materias = await api.get('/returnIndiceMateriasPortoFormulas');
      setMaterias(response_materias.data);

      const response = await api.get('/returnPortos');
      setPortos(response.data);

      const response_dolar = await api.get('/DolarApi');
      setDolarAtual(response_dolar.data);

      const respose_dolar_hora = await api.get('/DolarApi');
      setDolarHora(respose_dolar_hora.data);

      const response_micronutri = await api.get('/retornaMicroNutri');
      setMicronutri(response_micronutri.data);
      const respose_update = await api.get('/returnLastUpdateMaterias');
      setLastUpdate(respose_update.data);
    }
    fetchMyAPI();
  }, [props.history, props.token]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function getFormulas() {
    let auxPref = [];
    let auxRem = [];
    let auxMicro = [];
    pref.forEach((index) => (auxPref = [...auxPref, index.abrev]));
    rem.forEach((index) => (auxRem = [...auxRem, index.abrev]));
    Object.keys(micro).map((index) => (auxMicro = [...auxMicro, index + ':' + micro[index]])); // funciona com object.keys mas com foreach não, vai sbaer o pq dessa bosta
    console.log(micro);
    if (nitro === 0 && p2O5 === 0 && k2O === 0) {
      alert('Selecione os nutrientes!');
    } else if (porto === '') {
      alert('Selecione o porto de Origem!');
    } else {
      try {
        const user = JSON.parse(window.localStorage.getItem('user_data'));
        await api.post('/controletrafego', { user: user.ID, path: 'formulas' });
      } catch (error) {
        console.log('controle de trafego não registrado');
      }
      const response_formulas = await api.get('/returnFormulas', {
        params: {
          nitrogenio: nitro,
          p2o5: p2O5,
          k2o: k2O,
          porto: porto,
          dolar: 0,
          frete: frete,
          pref: auxPref.toString(),
          rem: auxRem.toString(),
          add_micro: auxMicro.join(['-']),
          dustrol: checked.toString(),
          inibidor: inibidor.toString(),
          valorJuroUSD, valorJuroBR, dataVencimento
        },
      });
      const response_formulas_default = await api.get('returnFormulasDefault', {
        params: {
          nitrogenio: nitro,
          p2o5: p2O5,
          k2o: k2O,
          porto: porto,
          frete: frete,
          dolar: 0,
        },
      });
      setFormulas('');
      setFormulas_default('');
      if (response_formulas.data === 'NOK') {
        alert('Não foi possível gerar formulas com essas configurações!');
      } else {
        setFormulas(response_formulas.data);
        if (response_formulas_default.data !== 'NOK') {
          setFormulas_default(response_formulas_default.data);
        }
      }
    }
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeInibidor = (event) => {
    setInibidor(event.target.checked);
  };

  function montaMicro(item, val) {
    if (val >= 50) {
      let aux = micro;
      aux[item] = val;
      setMicro(aux);
    } else {
      let aux = micro;
      aux[item] = 0;
      setMicro(aux);
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            -
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h1> Retorna Fórmulas</h1>

            <h3>Último Update no valor das máterias primas :{lastUpdate}</h3>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '40%',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={portos}
                    getOptionLabel={(option) => option.porto}
                    style={{ width: 450, marginTop: 10 }}
                    onInputChange={(e, v) => setPorto(v)}
                    renderInput={(params) => <TextField {...params} label="Porto" variant="outlined" />}
                  />
                </div>


              </div>

              <div
                style={{
                  height: 40,
                  marginLeft: 40,
                  position: 'relative',
                  bottom: 48,
                }}
              >
                <div style={{ marginBottom: 20, marginLeft: 15 }}>
                  <h2 style={{ bottom: 15, position: 'relative' }}>Micronutrientes</h2>
                  <hr
                    style={{
                      width: '10%',
                      height: '10%',
                      color: '#7CFC00',
                      bottom: 25,
                      position: 'relative',
                      right: '45%',
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      width: 200,
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: 20,
                      bottom: 28,
                      position: 'relative'
                    }}
                  >
                    {Object.keys(micronutri.slice(0, micronutri.length / 2)).map((item, index) => (
                      <TextField
                        variant="outlined"
                        label={micronutri[item].descNome === 'SSP micrado - Base Micrada' ? 'BM 02-18-00+2%Zn +1%B +1%Cu +2%Mn' : micronutri[item].descNome}
                        id={micronutri[item].abrev}
                        onChange={(evt) => montaMicro(micronutri[item].abrev, evt.target.value)}
                        inputProps={{ style: { fontSize: 15 } }} // font size of input text
                        InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                        style={{ width: 200, marginLeft: 15, marginBottom: 20 }}
                        defaultValue={0}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      width: 200,
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: 20,
                      marginLeft: 10,
                      bottom: 28,
                      position: 'relative'
                    }}
                  >
                    {Object.keys(micronutri.slice(5, micronutri.length)).map((item, index) => (
                      <TextField
                        variant="outlined"
                        label={micronutri[parseInt(item) + 5].descNome === 'SSP micrado - Base Micrada' ? 'BM 02-18-00+2%Zn +1%B +1%Cu +2%Mn' : micronutri[parseInt(item) + 5].descNome}
                        id={micronutri[parseInt(item) + 5].abrev}
                        onChange={(evt) => montaMicro(micronutri[parseInt(item) + 5].abrev, evt.target.value)}
                        inputProps={{ style: { fontSize: 15 } }} // font size of input text
                        InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                        style={{
                          width: 200,
                          marginLeft: 15,
                          marginBottom: 20,
                        }}
                        defaultValue={0}
                      />
                    ))}
                  </div>
                </div>
                <p style={{ color: '#696969', left: '3.5%', bottom: 50, position: 'relative' }}>obs: mínimo 50kg, valores menores serão ignorados no cálculo</p>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, }}>
              {/* <TextField
                style={{ border: "solid", borderWidth: 1, marginRight: 5 }}
                label="Dolar"
                id="Dolar"
                onChange={(evt) => setDolar(evt.target.value)}
              /> */}
              <p style={{ color: '#696969' }}>Dolar hora : {dolarHora}</p>
              <TextField variant="outlined" label="Frete R$" id="Frete" onChange={(evt) => setFrete(evt.target.value)} style={{ left: '2.8%', width: '11.5%' }} />
            </div>

            <div style={{ marginBottom: 15, marginTop: 15 }}>
              <div>
                <TextField
                  variant="outlined"
                  label="N"
                  id="N"
                  onChange={(evt) => setNitro(evt.target.value)}
                  inputProps={{ style: { fontSize: 15 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                  style={{ width: '11.5%' }}
                />

                <TextField
                  variant="outlined"
                  label="P2O5"
                  id="P2O5"
                  onChange={(evt) => setP2O5(evt.target.value)}
                  inputProps={{ style: { fontSize: 15 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                  style={{ width: '11.5%', marginLeft: 15 }}
                />
                <TextField
                  variant="outlined"
                  label="K20"
                  id="K20"
                  onChange={(evt) => setk2O(evt.target.value)}
                  inputProps={{ style: { fontSize: 15 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                  style={{ width: '11%', marginLeft: 15 }}
                />
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',

              }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '11.5%', marginTop: 20
                  }}
                >

                  <TextField variant="outlined" label={'Juro USD'} defaultValue={valorJuroUSD} id="Juro USD" onChange={(evt) => setValorJuroUSD(evt.target.value)} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '11.5%', marginTop: 20, marginLeft: 15
                  }}
                >

                  <TextField variant="outlined" label={'Juro BRL'} defaultValue={valorJuroBR} id="Juro BR" onChange={(evt) => setValorJuroBR(evt.target.value)} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '11.5%', marginTop: 20, marginLeft: 15
                  }}
                >

                  <TextField variant="outlined" label={'Data Vencimento'} defaultValue={dataVencimento} id="vencimento" onChange={(evt) => setDataVencimento(evt.target.value)} />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Autocomplete
                  multiple
                  id="multiple-limit-tags"
                  options={materias}
                  getOptionLabel={(option) => option.abrev}
                  onChange={(e, v) => setPref(v)}
                  style={{ width: 450, marginTop: 20 }}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Matérias obrigatórias nas fórmulas" placeholder="Matérias obrigatórias nas fórmulas" />}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Autocomplete
                  multiple
                  id="multiple-limit-tags"
                  options={materias}
                  getOptionLabel={(option) => option.abrev}
                  onChange={(e, v) => setRem(v)}
                  style={{ width: 450, marginTop: 20 }}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Matérias para remover das fórmulas" placeholder="Matérias para remover das fórmulas" />}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'primary checkbox' }} />
                <p style={{ color: '#696969' }}>Inserir Dustrol</p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Checkbox checked={inibidor} onChange={handleChangeInibidor} inputProps={{ 'aria-label': 'primary checkbox' }} />
                <p style={{ color: '#696969' }}>Inserir Inibidor NBPT</p>
              </div>
            </div>
          </div>
          <LoadingIndicator />
          <button
            onClick={() => trackPromise(getFormulas())}
            style={{
              height: 50,
              width: 250,
              borderRadius: 20,
              borderStyle: 'solid',
              background: 'linear-gradient(to right, #a9d046, #d3e05c)',
            }}
          >
            <p
              style={{
                marginTop: 13,
                fontStyle: 'bold',
                color: 'white',
                fontSize: 16,
              }}
            >
              Calcular Fechamentos
            </p>
          </button>
          {formulas !== '' ? (
            <div
              style={{
                display: 'flex',

                flexDirection: 'column',
              }}
            >
              <h2>Fechamento padrão das fórmulas</h2>
              {Object.keys(formulas_default).map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    borderWidth: '0.5px',
                    border: 'solid',
                    borderRadius: 30,
                    flexDirection: 'column',
                    marginBottom: 15,
                    borderColor: 'lightgrey',
                    height: 400,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: 15,
                    }}
                  >
                    {Object.keys(formulas_default[item]).map((item_, index_) =>
                      !item_.includes('price') & !item_.includes('nutri') ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 100,
                            marginTop: 19,
                          }}
                        >
                          <div
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                            }}
                          >
                            <p>{item_}</p>
                          </div>
                          <div style={{ height: '50px', textAlign: 'center' }}>
                            <p>{formulas_default[item][item_]}</p>
                          </div>
                          <div
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                            }}
                          >
                            <p>{formulas_default[item][item_] / 10} %</p>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )
                    )}

                    <div>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <h4
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                              paddingTop: 15,
                              width: 220,
                            }}
                          >
                            FCA (FOB Porto) Big Bag
                          </h4>
                          <p
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              position: 'relative',
                              bottom: 4,
                              width: 220,
                            }}
                          >
                            R$ {dolarAtual}
                          </p>
                        </div>
                        <div
                          style={{
                            height: '50px',
                            backgroundColor: '#E0FFFF',
                            width: 220,
                            top: 19,
                            position: 'relative',
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              top: 5,
                              position: 'relative',
                              width: 220,
                            }}
                          >
                            {' '}
                            $ {formulas_default[item]['total_price']}
                          </p>
                          <p
                            style={{
                              textAlign: 'center',
                              top: 15,
                              position: 'relative',
                              width: 220,
                            }}
                          >
                            {' '}
                            R$ {(formulas_default[item]['total_price'] * dolarAtual).toFixed(2)}{' '}
                          </p>
                        </div>
                      </div>
                      {formulas_default[item]['cif_total_price'] !== formulas_default[item]['total_price'] ? (
                        <div
                          style={{
                            display: 'flex',
                            position: 'relative',
                            bottom: '50px',
                          }}
                        >
                          <div
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <h4
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                backgroundColor: '#E0FFFF',
                                paddingTop: 15,
                                width: 220,
                              }}
                            >
                              Preço CIF frete
                            </h4>
                            <p
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                position: 'relative',
                                bottom: 4,
                                width: 220,
                              }}
                            >
                              R$ {dolarAtual}
                            </p>
                          </div>
                          <div
                            style={{
                              height: '50px',
                              backgroundColor: '#E0FFFF',
                              width: 220,
                              top: 19,
                              position: 'relative',
                            }}
                          >
                            <p
                              style={{
                                textAlign: 'center',
                                top: 5,
                                position: 'relative',
                                width: 220,
                              }}
                            >
                              {' '}
                              $ {formulas_default[item]['cif_total_price']}
                            </p>
                            <p
                              style={{
                                textAlign: 'center',
                                top: 15,
                                position: 'relative',
                                width: 220,
                              }}
                            >
                              {' '}
                              R$ {(formulas_default[item]['cif_total_price'] * dolarAtual).toFixed(2)}{' '}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            position: 'relative',
                            bottom: '50px',
                          }}
                        >
                          <div
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                backgroundColor: '#E0FFFF',
                                paddingTop: 15,
                                width: 220,
                              }}
                            ></p>
                            <p
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                position: 'relative',
                                bottom: 4,
                                width: 220,
                              }}
                            ></p>
                          </div>
                          <div
                            style={{
                              height: '50px',
                              backgroundColor: '#E0FFFF',
                              width: 220,
                              top: 19,
                              position: 'relative',
                            }}
                          >
                            <p
                              style={{
                                textAlign: 'center',
                                top: 5,
                                position: 'relative',
                                width: 220,
                              }}
                            ></p>
                            <p
                              style={{
                                textAlign: 'center',
                                top: 15,
                                position: 'relative',
                                width: 220,
                              }}
                            ></p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <h2
                    style={{
                      position: 'relative',
                      bottom: isSafari ? 40 : 120,
                    }}
                  >
                    {' '}
                    Composição
                  </h2>
                  <div
                    style={{
                      display: 'flex',
                      position: 'relative',
                      bottom: isSafari ? 40 : 120,
                    }}
                  >
                    <table style={{ width: '95%', marginRight: '5%' }}>
                      <thead>
                        <tr
                          style={{
                            height: '50px',
                            textAlign: 'center',
                            backgroundColor: '#E0FFFF',
                          }}
                        >
                          {Object.keys(formulas_default[item]).map((micro_nutri, index_materia) =>
                            micro_nutri.includes('nutri') & (formulas_default[item][micro_nutri] !== 0) ? (
                              <th
                                style={{
                                  width: '50px',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {micro_nutri.split('_')[0]}
                              </th>
                            ) : (
                              console.log('teste')
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: '50px' }}>
                          {Object.keys(formulas_default[item]).map((micro_nutri, index_materia) =>
                            micro_nutri.includes('nutri') & (formulas_default[item][micro_nutri] !== 0) ? (
                              <td
                                style={{
                                  height: '50px',
                                  textAlign: 'center',
                                }}
                              >
                                {console.log(formulas_default[item][micro_nutri] / 10)}
                                {console.log((formulas_default[item][micro_nutri] / 10).toString().slice(0, 4))}
                                {(formulas_default[item][micro_nutri] / 10).toString().slice(0, 5)} %
                              </td>
                            ) : (
                              console.log('teste')
                            )
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
              <h2>Fechamentos calculados pela plataforma AMR</h2>
              {Object.keys(formulas).map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    borderWidth: '0.5px',
                    border: 'solid',
                    borderRadius: 30,
                    flexDirection: 'column',
                    marginBottom: 15,
                    borderColor: 'lightgrey',
                    height: 400,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      borderWidth: '1px',

                      marginBottom: 15,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 200,
                      }}
                    >
                      {formulas[item]['limitada'] !== undefined ? (
                        (formulas[item]['limitada'] !== '') | (formulas[item]['limitada'].length !== 0) ? (
                          <div style={{ paddingTop: 18 }}>
                            <div
                              style={{
                                width: '100%',
                                height: '50px',
                                backgroundColor: '#E0FFFF',
                                display: 'flex',
                                position: 'relative',
                              }}
                            >
                              <h4 style={{ textAlign: 'center', paddingTop: -3 }}>Compatibilidade Limitada!</h4>
                            </div>
                            <div
                              style={{
                                width: '100%',
                                height: '55px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                position: 'relative',
                              }}
                            >
                              <AiOutlineExclamationCircle color="#ffff00" size={20} />
                            </div>
                            <div
                              style={{
                                width: '100%',
                                height: '50px',
                                backgroundColor: '#E0FFFF',
                                position: 'relative',
                                bottom: 12,
                              }}
                            >
                              <p
                                style={{
                                  textAlign: 'center',
                                  paddingTop: 15,
                                  fontSize: 10,
                                }}
                              >
                                {formulas[item]['limitada']}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div style={{ paddingTop: 18 }}>
                            <div
                              style={{
                                width: '100%',
                                height: '50px',
                                backgroundColor: '#E0FFFF',
                                display: 'flex',
                                position: 'relative',
                              }}
                            >
                              <p style={{ textAlign: 'center', paddingTop: 20 }}></p>
                            </div>
                            <div
                              style={{
                                width: '100%',
                                height: '55px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                position: 'relative',
                              }}
                            >
                              <AiFillCheckCircle color="#00ff00" size={20} />
                            </div>
                            <div
                              style={{
                                width: '100%',
                                height: '50px',
                                backgroundColor: '#E0FFFF',
                                position: 'relative',
                                bottom: 14,
                              }}
                            >
                              <p
                                style={{
                                  textAlign: 'center',
                                  paddingTop: 15,
                                  fontSize: 10,
                                }}
                              ></p>
                            </div>
                          </div>
                        )
                      ) : (
                        <div>
                          <div
                            style={{
                              width: '100%',
                              height: '50px',
                              backgroundColor: '#E0FFFF',
                            }}
                          >
                            <p style={{ textAlign: 'center', paddingTop: 15 }}></p>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              height: '55px',
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                              position: 'relative',
                            }}
                          >
                            <AiFillCheckCircle color="#00ff00" size={15} />
                          </div>
                          <div
                            style={{
                              width: '100%',
                              height: '50px',
                              backgroundColor: '#E0FFFF',
                              position: 'relative',
                              bottom: 14,
                            }}
                          >
                            <p
                              style={{
                                textAlign: 'center',
                                paddingTop: 15,
                                fontSize: 10,
                              }}
                            ></p>
                          </div>
                        </div>
                      )}
                    </div>
                    {Object.keys(formulas[item]).map((materia, index_materia) =>
                      materia.includes('peso') & ~materia.includes('total') & (formulas[item][materia] !== 0) ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 100,
                            marginTop: 19,
                          }}
                        >
                          <div
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                            }}
                          >
                            <h4>
                              {materia.split('_')[0]} {materia.split('_').length > 2 ? materia.split('_')[1] : <p></p>}
                            </h4>
                          </div>
                          <div style={{ height: '50px', textAlign: 'center' }}>
                            <p>{formulas[item][materia]} KG</p>
                          </div>
                          <div
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                            }}
                          >
                            <p> </p>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )
                    )}
                    <div>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <h4
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                              paddingTop: 15,
                              width: 180,
                            }}
                          >
                            FCA (FOB Porto) Big Bag
                          </h4>
                          <p
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              position: 'relative',
                              bottom: 4,
                              width: 180,
                            }}
                          >
                            R$ {formulas[item]['dolar']}
                          </p>
                        </div>
                        <div
                          style={{
                            height: '50px',
                            backgroundColor: '#E0FFFF',
                            width: 180,
                            top: 19,
                            position: 'relative',
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              top: 5,
                              position: 'relative',
                              width: 180,
                            }}
                          >
                            {' '}
                            $ {formulas[item]['preco_total']}
                          </p>
                          {/* <p
                            style={{
                              textAlign: 'center',
                              top: 5,
                              position: 'relative',
                              width: 180,
                            }}
                          >
                            {' '}
                            $ Juros {formulas[item]['preco_total_juros']}
                          </p> */}
                          <p
                            style={{
                              textAlign: 'center',
                              top: 15,
                              position: 'relative',
                              width: 180,
                            }}
                          >
                            {' '}
                            R$ {(formulas[item]['preco_total_brl'] ).toFixed(2)}{' '}
                          </p>
                          {/* <p
                            style={{
                              textAlign: 'center',
                              top: 15,
                              position: 'relative',
                              width: 180,
                            }}
                          >
                            {' '}
                            R$ Juros {formulas[item]['preco_total_brl_juros']}{' '}
                          </p> */}
                        </div>
                      </div>
                      {formulas[item]['preco_total_cif'] !== formulas[item]['preco_total'] ? (
                        <div
                          style={{
                            display: 'flex',
                            position: 'relative',
                            bottom: '50px',
                          }}
                        >
                          <div
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <h4
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                backgroundColor: '#E0FFFF',
                                paddingTop: 15,
                                width: 180,
                              }}
                            >
                              Preço CIF frete
                            </h4>
                            <p
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                position: 'relative',
                                bottom: 4,
                                width: 180,
                              }}
                            >
                              R$ {formulas[item]['dolar']}
                            </p>
                          </div>
                          <div
                            style={{
                              height: '50px',
                              backgroundColor: '#E0FFFF',
                              width: 180,
                              top: 19,
                              position: 'relative',
                            }}
                          >
                            <p
                              style={{
                                textAlign: 'center',
                                top: 5,
                                position: 'relative',
                                width: 180,
                              }}
                            >
                              {' '}
                              $ {formulas[item]['preco_total_cif']}
                            </p>
                            {/* <p
                              style={{
                                textAlign: 'center',
                                top: 5,
                                position: 'relative',
                                width: 180,
                              }}
                            >
                              {' '}
                              $ Juros {formulas[item]['preco_total_cif_juros']}
                            </p> */}
                            <p
                              style={{
                                textAlign: 'center',
                                top: 15,
                                position: 'relative',
                                width: 180,
                              }}
                            >
                              {' '}
                              R$ {(formulas[item]['preco_total_cif_brl']).toFixed(2)}{' '}
                            </p>
                            {/* <p
                              style={{
                                textAlign: 'center',
                                top: 15,
                                position: 'relative',
                                width: 220,
                              }}
                            >
                              {' '}
                              R$ Juros {formulas[item]['preco_total_brl_cif_juros'] }{' '}
                            </p> */}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            position: 'relative',
                            bottom: '50px',
                          }}
                        >
                          <div
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                backgroundColor: '#E0FFFF',
                                paddingTop: 15,
                                width: 180,
                              }}
                            ></p>
                            <p
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                position: 'relative',
                                bottom: 4,
                                width: 180,
                              }}
                            ></p>
                          </div>
                          <div
                            style={{
                              height: '50px',
                              backgroundColor: '#E0FFFF',
                              width: 180,
                              top: 19,
                              position: 'relative',
                            }}
                          >
                            <p
                              style={{
                                textAlign: 'center',
                                top: 5,
                                position: 'relative',
                                width: 180,
                              }}
                            ></p>
                            <p
                              style={{
                                textAlign: 'center',
                                top: 15,
                                position: 'relative',
                                width: 180,
                              }}
                            ></p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <h4
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                              paddingTop: 15,
                              width: 180,
                            }}
                          >
                            Valor com Juros 
                          </h4>
                          <p
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              position: 'relative',
                              bottom: 4,
                              width: 180,
                            }}
                          >
                           Pagamento:{dataVencimento}
                          </p>
                        </div>
                        <div
                          style={{
                            height: '50px',
                            backgroundColor: '#E0FFFF',
                            width: 180,
                            top: 19,
                            position: 'relative',
                          }}
                        >
                        
                          <p
                            style={{
                              textAlign: 'center',
                              top: 5,
                              position: 'relative',
                              width: 180,
                            }}
                          >
                            {' '}
                            $  {formulas[item]['preco_total_juros']}
                          </p>
                          
                          <p
                            style={{
                              textAlign: 'center',
                              top: 15,
                              position: 'relative',
                              width: 180,
                            }}
                          >
                            {' '}
                            R$  {formulas[item]['preco_total_brl_juros']}{' '}
                          </p>
                        </div>
                      </div>
                      {formulas[item]['preco_total_cif'] !== formulas[item]['preco_total'] ? (
                        <div
                          style={{
                            display: 'flex',
                            position: 'relative',
                            bottom: '50px',
                          }}
                        >
                          <div
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <h4
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                backgroundColor: '#E0FFFF',
                                paddingTop: 15,
                                width: 180,
                              }}
                            >
                             
                            </h4>
                            <p
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                position: 'relative',
                                bottom: 4,
                                width: 180,
                              }}
                            >
                            
                            </p>
                          </div>
                          <div
                            style={{
                              height: '50px',
                              backgroundColor: '#E0FFFF',
                              width: 180,
                              top: 19,
                              position: 'relative',
                            }}
                          >
                            
                            <p
                              style={{
                                textAlign: 'center',
                                top: 5,
                                position: 'relative',
                                width: 180,
                              }}
                            >
                              {' '}
                              $  {formulas[item]['preco_total_cif_juros']}
                            </p>
                           
                            <p
                              style={{
                                textAlign: 'center',
                                top: 15,
                                position: 'relative',
                                width: 180,
                              }}
                            >
                              {' '}
                              R$  {formulas[item]['preco_total_brl_cif_juros'] }{' '}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            position: 'relative',
                            bottom: '50px',
                          }}
                        >
                          <div
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                backgroundColor: '#E0FFFF',
                                paddingTop: 15,
                                width: 180,
                              }}
                            ></p>
                            <p
                              style={{
                                height: '50px',
                                textAlign: 'center',
                                position: 'relative',
                                bottom: 4,
                                width: 180,
                              }}
                            ></p>
                          </div>
                          <div
                            style={{
                              height: '50px',
                              backgroundColor: '#E0FFFF',
                              width: 180,
                              top: 19,
                              position: 'relative',
                            }}
                          >
                            <p
                              style={{
                                textAlign: 'center',
                                top: 5,
                                position: 'relative',
                                width: 180,
                              }}
                            ></p>
                            <p
                              style={{
                                textAlign: 'center',
                                top: 15,
                                position: 'relative',
                                width: 180,
                              }}
                            ></p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <h2
                    style={{
                      marginLeft: 190,
                      position: 'relative',
                      bottom: isSafari ? 40 : 120,
                    }}
                  >
                    {' '}
                    Composição
                  </h2>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: 190,
                      position: 'relative',
                      bottom: isSafari ? 40 : 120,
                    }}
                  >
                    <table style={{ width: '95%', marginRight: '5%' }}>
                      <thead>
                        <tr
                          style={{
                            height: '50px',
                            textAlign: 'center',
                            backgroundColor: '#E0FFFF',
                          }}
                        >
                          <th style={{ width: '50px' }}>N</th>
                          <th style={{ width: '50px' }}>P2O5</th>
                          <th style={{ width: '50px' }}>K2O</th>
                          {Object.keys(formulas[item]).map((micro_nutri, index_materia) =>
                            micro_nutri.includes('nutri') & (formulas[item][micro_nutri] !== 0) ? (
                              <th
                                style={{
                                  width: '50px',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {micro_nutri.split('_')[0]}
                              </th>
                            ) : (
                              console.log('teste')
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: '50px' }}>
                          <td style={{ height: '50px', textAlign: 'center' }}>{formulas[item]['total_N'] / 10} %</td>
                          <td style={{ height: '50px', textAlign: 'center' }}>{formulas[item]['total_P2O5'] / 10} %</td>
                          <td style={{ height: '50px', textAlign: 'center' }}>{formulas[item]['total_k2o'] / 10} %</td>
                          {Object.keys(formulas[item]).map((micro_nutri, index_materia) =>
                            micro_nutri.includes('nutri') & (formulas[item][micro_nutri] !== 0) ? (
                              <td
                                style={{
                                  height: '50px',
                                  textAlign: 'center',
                                }}
                              >
                                {console.log(formulas[item][micro_nutri] / 10)}
                                {console.log((formulas[item][micro_nutri] / 10).toString().slice(0, 4))}
                                {(formulas[item][micro_nutri] / 10).toString().slice(0, 5)} %
                              </td>
                            ) : (
                              console.log('teste')
                            )
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div></div>
          )}
          <h2> Tabela de Preços CFR $/Ton</h2>
          {materias !== '' ? (
            <div
              style={{
                padding: 10,
                width: 400,
                border: 'solid',
                borderWidth: 1,
                borderColor: 'lightgrey',
                borderRadius: 20,
              }}
            >
              {Object.keys(materias).map((index) =>
                index % 2 === 0 ? (
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    {console.log(index)}
                    <p style={{ width: 210 }}>{materias[index].descNome} </p>
                    <span>{'    '}</span>
                    <p>$ {materias[index].price}</p>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: 'lightgrey',
                    }}
                  >
                    {console.log(index)}
                    <p style={{ width: 210 }}>{materias[index].descNome} </p>
                    <span>{'    '}</span>
                    <p>$ {materias[index].price}</p>
                  </div>
                )
              )}
            </div>
          ) : (
            <div></div>
          )}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  Spaces: {
    width: '30%',
  },

  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    borderBottom: 'none',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DevFormulas);
