/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import api from '../../../services/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import AmrLogo from '../../../assets/AMR horizontal.png';
import isSuper from '../../../utils/utils.js'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function ConfigMaterias(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [materias, setMaterias] = useState();
  const [toUpdate, setToUpdate] = useState([]);
  const [toUpdateFreteMar, setToUpdateFreteMar] = useState([]);
  const [toUpdateAtivo, setToUpdateAtivo] = useState([]);
  const [toUpdateComp, setToUpdateComp] = useState([]);
  //novas fórmulas
  const [abreviacao, setAbreviacao] = useState('');
  const [descricao, setDescricao] = useState('');
  const [nitrogenio, setNitrogenio] = useState(0);
  const [p2o5, setP2o5] = useState(0);
  const [k2o, setK2o] = useState(0);
  const [ca, setCa] = useState(0);
  const [s, setS] = useState(0);
  const [mg, setMg] = useState(0);
  const [zn, setZn] = useState(0);
  const [b, setB] = useState(0);

  const [cu, setCu] = useState(0);
  const [mn, setMn] = useState(0);
  const [mo, setMo] = useState(0);
  const [si, setSi] = useState(0);
  const [cl, setCl] = useState(0);
  const [is_, setIs_] = useState(0);
  const [price, setPrice] = useState(0);
  const [frete_mar, setFrete_mar] = useState(0);
  const [ativo_formulas, setAtivo_formulas] = useState(1);
  const [lastUpdate, setLastUpdate] = useState('');
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (((props.token?.token === undefined) & (window.localStorage.getItem('token') === null) )|(isSuper() !== true)) {
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      const response = await api.get('/returnMaterias');
      setMaterias(response.data);
      const respose_update = await api.get('/returnLastUpdateMaterias');
      setLastUpdate(respose_update.data);
    }
    fetchMyAPI();
  }, []);

  const handleUpdateMaterias = (item, item_, target) => {
    if (item_ === 'frete_mar') {
      let aux = toUpdateFreteMar;
      aux[materias[item]['abreviacao']] = target;
      setToUpdateFreteMar(aux);
    } else if (item_ === 'price') {
      let aux = toUpdate;
      aux[materias[item]['abreviacao']] = target;
      setToUpdate(aux);
    } else if (item_ === 'ativo_formulas') {
      let aux = toUpdateAtivo;
      aux[materias[item]['abreviacao']] = target;
      setToUpdateAtivo(aux);
    } else {
      let aux = toUpdateComp;
      aux[materias[item]['abreviacao'] + '-' + item_] = target;
      setToUpdateComp(aux);
    }
  };

  const handleInsertChange = (item_, target) => {
    if (item_ === 'abreviacao') {
      setAbreviacao(target);
    } else if (item_ === 'descricao') {
      setDescricao(target);
    } else if (item_ === 'nitrogenio') {
      setNitrogenio(target);
    } else if (item_ === 'p2o5') {
      setP2o5(target);
    } else if (item_ === 'k2o') {
      setK2o(target);
    } else if (item_ === 'ca') {
      setCa(target);
    } else if (item_ === 's') {
      setS(target);
    } else if (item_ === 'mg') {
      setMg(target);
    } else if (item_ === 'zn') {
      setZn(target);
    } else if (item_ === 'b') {
      setB(target);
    } else if (item_ === 'cu') {
      setCu(target);
    } else if (item_ === 'mn') {
      setMn(target);
    } else if (item_ === 'mo') {
      setMo(target);
    } else if (item_ === 'si') {
      setSi(target);
    } else if (item_ === 'cl') {
      setCl(target);
    } else if (item_ === 'is_') {
      setIs_(target);
    } else if (item_ === 'price') {
      setPrice(target);
    } else if (item_ === 'frete_mar') {
      setFrete_mar(target);
    } else if (item_ === 'ativo_formulas') {
      setAtivo_formulas(target);
    }
  };

  async function insertMateria() {
    if ((abreviacao === '') | (descricao === '')) {
      alert('Adicione uma abreviação/Descrição para poder adicionar as fórmulas!');
    } else {
      const response = await api.get('/adicionaMaterias', {
        params: {
          abreviacao,
          descricao,
          nitrogenio,
          p2o5,
          k2o,
          ca,
          s,
          mg,
          zn,
          b,
          cu,
          mn,
          mo,
          si,
          cl,
          is_,
          price,
          frete_mar,
          ativo_formulas,
        },
      });
      if (response.data === 'NOK') {
        alert('Erro ao atualizar matérias!');
        window.location.reload();
      } else {
        alert('Preços alterados com sucesso!');
        window.location.reload();
      }
    }
  }

  async function handlePriceChange() {
    let aux = '';

    Object.keys(toUpdate).forEach((item) => (aux = aux + item + '#' + toUpdate[item] + ';'));
    let auxFreteMar = '';

    Object.keys(toUpdateFreteMar).forEach((item) => (auxFreteMar = auxFreteMar + item + '#' + toUpdateFreteMar[item] + ';'));

    let auxAtivo = '';

    Object.keys(toUpdateAtivo).forEach((item) => (auxAtivo = auxAtivo + item + '#' + toUpdateAtivo[item] + ';'));

    let auxComp = '';

    Object.keys(toUpdateComp).forEach((item) => (auxComp = auxComp + item + '#' + toUpdateComp[item] + ';'));

    console.log(auxFreteMar);
    const response = await api.get('/modificaMateriaADM', {
      params: {
        toUpdate: aux,
        toUpdateFrete: auxFreteMar,
        toUpdateAtivo: auxAtivo,
        toUpdateComp: auxComp,
      },
    });
    if (response.data === 'NOK') {
      alert('Erro ao atualizar matérias!');
      window.location.reload();
    } else {
      alert('Preços alterados com sucesso!');
      window.location.reload();
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Configuração de matérias
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h1>Config Materias</h1>
          </div>
          <div>
            <h3>Último Update :{lastUpdate}</h3>
          </div>
          <div>
            {materias !== undefined ? (
              <table style={{ border: '1px solid #FF0000' }}>
                <thead>
                  <tr style={{ border: '1px solid #FF0000' }}>
                    <th style={{ border: '1px solid #FF0000' }}>Abreviação</th>
                    <th style={{ border: '1px solid #FF0000' }}>Nome</th>

                    <th style={{ border: '1px solid #FF0000', width: 50 }}>N</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>P2O5</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>K2O</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Ca</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>S</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Mg</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Zn</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>B</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Cu</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Mn</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Mo</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Si</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Cl</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>IS</th>
                    <th style={{ border: '1px solid #FF0000' }}>Preço FOB</th>
                    <th style={{ border: '1px solid #FF0000' }}>Frete Maritimo</th>
                    <th style={{ border: '1px solid #FF0000' }}>Ativo (1 = Ativo/ 0 = Inativo/ -1 = Deletar)</th>
                    <th style={{ border: '1px solid #FF0000' }}>Preço CFR</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(materias).map((item, index) => (
                    <tr key={item} style={{ border: '1px solid #FF0000' }}>
                      {Object.keys(materias[item]).map((item_, index_) =>
                        (item_ === 'abreviacao') | (item_ === 'descricao') ? (
                          <td style={{ border: '1px solid #FF0000' }}>{materias[item][item_]}</td>
                        ) : (
                          <td>
                            <TextField onChange={(e) => handleUpdateMaterias(item, item_, e.target.value)} style={{ border: '1px solid #FF0000' }} label={materias[item][item_]} />
                          </td>
                        )
                      )}
                      <td style={{ border: '1px solid #FF0000' }}>{materias[item]['price'] + materias[item]['frete_mar']}</td>
                    </tr>
                  ))}
                  <td></td>
                </tbody>
              </table>
            ) : (
              console.log(materias)
            )}
            <button onClick={() => handlePriceChange()}> Alterar Preços </button>
          </div>
          <div>
            <h1> Adicionar Matérias </h1>
          </div>
          {materias !== undefined ? (
            <div>
              <table style={{ border: '1px solid #FF0000' }}>
                <thead>
                  <tr style={{ border: '1px solid #FF0000' }}>
                    <th style={{ border: '1px solid #FF0000' }}>Abreviação</th>
                    <th style={{ border: '1px solid #FF0000' }}>Nome</th>

                    <th style={{ border: '1px solid #FF0000', width: 50 }}>N</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>P2O5</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>K2O</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Ca</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>S</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Mg</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Zn</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>B</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Cu</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Mn</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Mo</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Si</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>Cl</th>
                    <th style={{ border: '1px solid #FF0000', width: 50 }}>IS</th>
                    <th style={{ border: '1px solid #FF0000' }}>Preço FOB</th>
                    <th style={{ border: '1px solid #FF0000' }}>Frete Maritimo</th>
                    <th style={{ border: '1px solid #FF0000' }}>Ativo (1 = Ativo/ 0 = Inativo)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ border: '1px solid #FF0000' }}>
                    {Object.keys(materias[0]).map((item_, index_) => (
                      <td>
                        <TextField onChange={(e) => handleInsertChange(item_, e.target.value)} style={{ border: '1px solid #FF0000' }} />
                      </td>
                    ))}
                  </tr>

                  <td></td>
                </tbody>
              </table>
              <button onClick={() => insertMateria()}> Adicionar matéria</button>
            </div>
          ) : (
            console.log(materias)
          )}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigMaterias);
