/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import api from '../../../services/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import AmrLogo from '../../../assets/AMR horizontal.png';
import Autocomplete from '@material-ui/lab/Autocomplete';
import isSuper from '../../../utils/utils.js'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function ControleUtilizacao(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [value, setValue] = React.useState(usuarios[0] | {});
  const [dados, setDados] = React.useState([]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (((props.token?.token === undefined) & (window.localStorage.getItem('token') === null) )|(isSuper() !== true)) {
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      const response = await api.get('/cadastrados');
      const obj = response.data;
      const size = Object.values(obj).length - 1;
      if (size > 0) {
        for (let m = 0; m <= size; m++) {
          usuarios.push(obj[m]);
        }
      }
    }
    fetchMyAPI();
  }, []);

  async function retornaDados(usr) {
    const id = usr !== null ? usr.ID : null;
    const params = {
      ID: id,
    };
    const responsedados = await api.get('/dadoscontroletrafego', { params });

    const size = Object.values(responsedados.data).length - 1;
    const _dados = [];
    if (size > 0) {
      for (let m = 0; m <= size; m++) {
        _dados.push(responsedados.data[m]);
      }
    }

    setDados(_dados);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Controle de Utilização da Plataforma
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h1>Controle de Utilização da Plataforma</h1>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Autocomplete
              id="combo-box-demo"
              options={usuarios}
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
                retornaDados(newValue);
              }}
              getOptionLabel={(option) => option.login}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label={'Usuário'} />}
            />
          </div>
          <div>
            <table style={{ border: '1px solid #FF0000' }}>
              <thead>
                <tr style={{ border: '1px solid #FF0000' }}>
                  <th style={{ border: '1px solid #FF0000', width: 200 }}>Usuário</th>
                  <th style={{ border: '1px solid #FF0000', width: 200 }}>Tela Acessada</th>

                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Número de Acessos</th>
                  <th style={{ border: '1px solid #FF0000', width: 200 }}>Último em</th>
                </tr>
              </thead>
              <tbody>
                {dados.map((result, index) => (
                  <tr style={{ border: '1px solid #FF0000' }}>
                    <td style={{ border: '1px solid #FF0000' }}>{result.login}</td>
                    <td style={{ border: '1px solid #FF0000' }}>{result.url_path}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{result.numero}</td>
                    <td style={{ border: '1px solid #FF0000' }}>{result.ultimo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ControleUtilizacao);
