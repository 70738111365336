/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import api from '../../../services/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AmrLogo from '../../../assets/AMR horizontal.png';
import isSuper from '../../../utils/utils.js'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function ConfigFretesHistorico(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  //const [toUpdateComp, setToUpdateComp] = useState([]);
  const [cidade, setCidade] = useState('');
  const [ano, setAno] = useState(2020);
  const [valorFrete, setValorFrete] = useState([]);
  const [cidades, setCidades] = useState('');
  const [fretes, setFretes] = useState('');
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (((props.token?.token === undefined) & (window.localStorage.getItem('token') === null) )|(isSuper() !== true)) {
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      // const response = await api.get("/retornaHistoricoADM");
      // setCompat(response.data);
      const response_cidades = await api.get('/returnCidadesHistoricoADM');
      setCidades(response_cidades.data);
    }
    fetchMyAPI();
  }, []);

  async function adicionaFrete() {
    let aux = '';
    Object.keys(valorFrete).forEach((item) => (aux = aux + item + '#' + valorFrete[item] + ';'));

    const response = await api.get('/adicionaHistoricoFretesADM', {
      params: {
        cidade: cidade,
        ano: ano,
        valorFrete: aux,
      },
    });
    const response_cidades = await api.get('/retornaHistoricoADM', {
      params: {
        cidade: cidade,
      },
    });
    setFretes(response_cidades.data);
    console.log(response.data);
  }
  async function retornaFretes() {
    const response_cidades = await api.get('/retornaHistoricoADM', {
      params: {
        cidade: cidade,
      },
    });
    setFretes(response_cidades.data);
    console.log(response_cidades.data);
  }

  const constroiFrete = (mes, valor) => {
    let aux = valorFrete;
    aux[mes + '-' + ano.toString()] = valor;
    setValorFrete(aux);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Configurar Histórico de Fretes
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h1>Config Historico de Fretes</h1>
          </div>
          <div>
            <h1> Adicionar Frete </h1>
          </div>

          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Cidade</p>
              <Autocomplete
                id="combo-box-demo"
                options={cidades}
                getOptionLabel={(option) => option.cidade}
                style={{ width: 300 }}
                onInputChange={(e, v) => setCidade(v)}
                label={cidade}
                renderInput={(params) => <TextField {...params} label={cidade} variant="outlined" />}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Ano</p>
              <Autocomplete
                id="combo-box-demo"
                options={[{ name: '2017' }, { name: '2018' }, { name: '2019' }, { name: '2020' }, { name: '2021' }, { name: '2022' }]}
                getOptionLabel={(option) => option.name}
                style={{ width: 300 }}
                onInputChange={(e, v) => setAno(v)}
                label={ano}
                renderInput={(params) => <TextField {...params} label={ano} variant="outlined" />}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Janeiro</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('jan', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Fevereiro</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('fev', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Março</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('mar', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Abril</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('abr', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Maio</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('mai', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Junho</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('jun', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Julho</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('jul', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Agosto</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('ago', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}> Setembro</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('set', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Outubro</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('out', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Novembro</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('nov', evt.target.value)} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ width: 100 }}>Dezembro</p>
              <TextField style={{ border: 'solid', borderWidth: 1 }} label="Frete R$" id="Frete" onChange={(evt) => constroiFrete('dez', evt.target.value)} />
            </div>
            <button onClick={() => adicionaFrete()}> Adicionar Frete</button>
            <button onClick={() => retornaFretes()}> Retornar Fretes da Cidade Selecionada</button>
          </div>
          {fretes !== '' ? (
            <table>
              <thead>
                <tr style={{ border: '1px solid #FF0000' }}>
                  <th style={{ border: '1px solid #FF0000' }}>Ano</th>
                  <th style={{ border: '1px solid #FF0000' }}>Janeiro</th>
                  <th style={{ border: '1px solid #FF0000' }}>Fevereiro</th>
                  <th style={{ border: '1px solid #FF0000' }}>Março</th>
                  <th style={{ border: '1px solid #FF0000' }}>Abril</th>
                  <th style={{ border: '1px solid #FF0000' }}>Maio</th>
                  <th style={{ border: '1px solid #FF0000' }}>Junho</th>
                  <th style={{ border: '1px solid #FF0000' }}>Julho</th>
                  <th style={{ border: '1px solid #FF0000' }}>Agosto</th>
                  <th style={{ border: '1px solid #FF0000' }}>Setembro</th>
                  <th style={{ border: '1px solid #FF0000' }}>Outubro</th>
                  <th style={{ border: '1px solid #FF0000' }}>Novembro</th>
                  <th style={{ border: '1px solid #FF0000' }}>Dezembro</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(fretes).map((item, value) => (
                  <tr key={item}>
                    <td style={{ border: '1px solid #FF0000' }}>{item}</td>
                    {Object.keys(fretes[item]).map((item_, value_) => (
                      <td style={{ border: '1px solid #FF0000' }}>
                        {console.log(fretes[item][item_], item_, value_)}
                        {fretes[item][item_]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div></div>
          )}

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigFretesHistorico);
