import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';

import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { TextField } from '@material-ui/core';
import api from '../../../services/api';
import AmrLogo from '../../../assets/AMR horizontal.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function Config(props) {
  const [origens, setOrigens] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [origem, setOrigem] = useState(''); //variáveis a serem jogadas na api
  const [destino, setDestino] = useState('');
  const [rotas, setRotas] = useState('');
  useEffect(() => {
    if ((props.token?.token === undefined) & (window.localStorage.getItem('token') === null)) {
      props.history.push({ pathname: '/' });
    }

    async function fetchMyAPI() {
      const response = await api.get('/returnOrigens');
      setOrigens(response.data);

      const response_destino = await api.get('/returnDestinos');
      setDestinos(response_destino.data);
      const response_rotas = await api.get('/retornaRotasConfig', {
        params: {
          user: props.user.user,
        },
      });
      setRotas(response_rotas.data);
      console.log(response_rotas.data);
    }
    fetchMyAPI();
  }, [props.history, props.token, props.user]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function saveConfigFretes() {
    const cidadeOrigem = origem.split('-')[0].trim();
    const estadoOrigem = origem.split('-')[1].trim();
    const cidadeDestino = destino.split('-')[0].trim();
    const estadoDestino = destino.split('-')[1].trim();
    const user = props.user.user;
    const response = await api.get('/adicionarOrigemDestino', {
      params: {
        origem: cidadeOrigem,
        estado_origem: estadoOrigem,
        destino: cidadeDestino,
        estado_destino: estadoDestino,
        user: user,
      },
    });
    console.log(response.data);
  }

  async function removeRotas(index) {
    const user = props.user.user;
    const response = await api.get('/removerOrigemDestino', {
      params: {
        rota: rotas[index],
        user: user,
      },
    });
    console.log(response.data);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Precificação Portos
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h1> Configurações de exibição de fretes</h1>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Autocomplete
                id="combo-box-demo"
                options={origens}
                getOptionLabel={(option) => option.origemEstado}
                style={{ width: 300 }}
                onInputChange={(e, v) => setOrigem(v)}
                renderInput={(params) => <TextField {...params} label="Origem" variant="outlined" />}
              />
              <Autocomplete
                id="combo-box-demo"
                options={destinos}
                getOptionLabel={(option) => option.destinoEstado}
                style={{ width: 300 }}
                onInputChange={(e, v) => setDestino(v)}
                renderInput={(params) => <TextField {...params} label="Destino" variant="outlined" />}
              />
              <button onClick={() => saveConfigFretes()}> Adicionar Rota </button>
            </div>
            {rotas !== '' ? (
              <div>
                {Object.keys(rotas).map((item, index) => (
                  <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                    <p style={{ width: 200 }}>
                      {rotas[item].origem}
                      {' / '}
                      {rotas[item].estado_origem}
                    </p>

                    <p style={{ width: 200 }}>
                      {rotas[item].destino}
                      {' / '}

                      {rotas[item].estado_destino}
                    </p>

                    <button style={{ width: 150 }} onClick={() => removeRotas(index)}>
                      {' '}
                      Remover Rota
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              console.log(rotas)
            )}
          </div>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  Spaces: {
    width: '30%',
  },

  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    borderBottom: 'none',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Config);
