import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';

import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import api from '../../../services/api';
import AmrLogo from '../../../assets/AMR horizontal.png';
import Portos from '../../../assets/portos.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function PrecoPorto(props) {
  const [portos, setPortos] = useState([]);
  const [porto, setPorto] = useState({});
  const [dolar, setDolar] = useState(0);
  const [dolarHora, setDolarHora] = useState(0);
  const [precificado, setPrecificado] = useState('');
  // const [destinos, setDestinos] = useState([]);
  // const [destino, setDestino] = useState("");
  // const [fretes, setFretes] = useState("");
  const [frete, setFrete] = useState(0);
  const [lastUpdate, setLastUpdate] = useState('');

  useEffect(() => {
    if (props.token?.token === undefined) {
      //   alert("Você não está logado!");
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      const response = await api.get('/returnPortos');
      setPortos(response.data);
      // const response_destino = await api.get("/returnDestinos");
      // setDestinos(response_destino.data);
      const respose_dolar_hora = await api.get('/DolarApi');
      setDolarHora(respose_dolar_hora.data);
      const respose_update = await api.get('/returnLastUpdateMaterias');
      setLastUpdate(respose_update.data);
    }
    fetchMyAPI();
  }, [props.history, props.token]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function getPrices() {
    try {
      const user = JSON.parse(window.localStorage.getItem('user_data'));
      await api.post('/controletrafego', { user: user.ID, path: 'Precificação Porto' });
    } catch (error) {
      console.log('controle de trafego não registrado');
    }

    const response = await api.get('/returnPrecificacaoPortos', {
      params: {
        porto: porto,
        dolar: dolar,
        frete: frete,
      },
    });

    setPrecificado(response.data);
    // const cidadeOrigem = porto.split("/")[0].trim();
    // const estadoOrigem = porto.split("/")[1].trim();
    // const cidadeDestino = destino.split("-")[0].trim();
    // const estadoDestino = destino.split("-")[1].trim();
    // const response_frete = await api.get("/fretesSemanal", {
    //   params: {
    //     origem: cidadeOrigem,
    //     estado_origem: estadoOrigem,
    //     destino: cidadeDestino,
    //     estado_destino: estadoDestino,
    //   },
    // });
    // setFretes(response_frete.data);
    // console.log(response_frete.data);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Precificação Portos
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ marginBottom: 20 }}>
            <h1> Precificação Porto</h1>
            <h3>
              Último Update no valor das máterias primas :<strong> {lastUpdate.split('-')[2] + '/' + lastUpdate.split('-')[1] + '/' + lastUpdate.split('-')[0]}</strong>
            </h3>

            <p>Dolar hora : {dolarHora}</p>
            <TextField
              variant="outlined"
              style={{
                width: 200,
              }}
              label="Dolar"
              id="combo-box-demo"
              onChange={(evt) => setDolar(evt.target.value)}
            />
            <TextField
              variant="outlined"
              style={{
                width: 200,
              }}
              label="Frete R$"
              id="combo-box-demo"
              onChange={(evt) => setFrete(evt.target.value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Autocomplete
              id="combo-box-demo"
              options={portos}
              getOptionLabel={(option) => option.porto}
              style={{
                width: 200,
              }}
              onInputChange={(e, v) => setPorto(v)}
              renderInput={(params) => <TextField {...params} label="Porto" variant="outlined" />}
            />
            {/* Destino
            <Autocomplete
              id="combo-box-demo"
              options={destinos}
              getOptionLabel={(option) => option.destinoEstado}
              style={{ width: 300 }}
              onInputChange={(e, v) => setDestino(v)}
              renderInput={(params) => (
                <TextField {...params} label="Destino" variant="outlined" />
              )}
            /> */}
            <button
              style={{
                marginTop: 20,
                height: 50,
                width: 250,
                borderRadius: 20,
                borderStyle: 'solid',
                background: 'linear-gradient(to right, #a9d046, #d3e05c)',
              }}
              onClick={() => getPrices()}
            >
              {' '}
              <p
                style={{
                  marginTop: 13,
                  fontStyle: 'bold',
                  color: 'white',
                  fontSize: 16,
                }}
              >
                Calcular
              </p>{' '}
            </button>
          </div>
          {precificado !== '' ? (
            <div>
              <div
                style={{
                  border: '1px solid lightgray',
                  borderRadius: 20,
                  marginTop: 40,
                  padding: 10,
                }}
              >
                <table
                  style={{
                    borderCollapse: 'collapse',
                    borderSpacing: 0,
                  }}
                >
                  <thead style={{ height: 50 }}>
                    <tr>
                      <th style={{ width: 200, textAlign: 'center' }}>Matéria Prima</th>
                      <th style={{ width: 200, textAlign: 'center' }}>CFR (USD/t)</th>
                      <th style={{ width: 200, textAlign: 'center' }}>FCA Granel Sobre Rodas Porto (USD/t)</th>
                      <th style={{ width: 200, textAlign: 'center', padding: 10 }}>FCA Granel Sobre Rodas Porto (R$/t)</th>
                      <th style={{ width: 200, textAlign: 'center' }}>FCA Big Bag Sobre Rodas Porto (USD/t)</th>
                      <th style={{ width: 200, textAlign: 'center' }}>FCA Big Bag Sobre Rodas Porto (R$/t)</th>
                      <th style={{ width: 200, textAlign: 'center' }}>CIP - Posto Fazenda (USD/t)</th>
                      <th style={{ width: 200, textAlign: 'center' }}>CIP - Posto Fazenda (R$/t)</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Object.keys(precificado).map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          background: index % 2 === 0 ? 'lightgray' : 'white',
                          height: 50,
                        }}
                      >
                        <td style={{ width: 200, textAlign: 'center' }}>{precificado[item].materia_prima_granel}</td>
                        <td style={{ width: 200, textAlign: 'center' }}>{precificado[item].CFR_USD}</td>
                        <td style={{ width: 200, textAlign: 'center' }}>{precificado[item].FOB_porto_granel}</td>
                        <td style={{ width: 200, textAlign: 'center' }}>{precificado[item].FOB_porto_granel_RS}</td>
                        <td style={{ width: 200, textAlign: 'center' }}>{precificado[item].FOB_porto_bag}</td>
                        <td style={{ width: 200, textAlign: 'center' }}>{precificado[item].FOB_porto_bag_RS}</td>
                        <td style={{ width: 200, textAlign: 'center' }}>{precificado[item].CIF_frete}</td>
                        <td style={{ width: 200, textAlign: 'center' }}>{precificado[item].CIF_frete_RS}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p style={{ fontSize: 9 }}>EXW – Ex Works – Na Origem (local de entrega nomeado)</p>
                  <p style={{ fontSize: 9 }}>FCA – Free Carrier – Livre No Transportador (local de entrega nomeado)</p>
                  <p style={{ fontSize: 9 }}>FAS – Free Alongside Ship – Livre Ao Lado Do Navio (porto de embarque nomeado)</p>
                  <p style={{ fontSize: 9 }}>FOB – Free On Board – Livre A Bordo (porto de embarque nomeado)</p>
                  <p style={{ fontSize: 9 }}>CPT – Carriage Paid To – Transporte Pago Até (local de destino nomeado)</p>
                  <p style={{ fontSize: 9 }}>CIP – Carriage And Insurance Paid To – Transporte E Seguro Pagos Até (local de destino nomeado)</p>
                  <p style={{ fontSize: 9 }}>CFR – Cost And Freight – Custo E Frete (porto de destino nomeado)</p>
                  <p style={{ fontSize: 9 }}>CIF – Cost Insurance And Freight – Custo, Seguro E Frete (porto de destino nomeado)</p>
                  <p style={{ fontSize: 9 }}>DAP – Delivered At Place – Entregue No Local (local de destino nomeado)</p>
                  <p style={{ fontSize: 9 }}>DPU – Delivered At Place Unloaded – Entregue No Local Desembarcado (Local de destino nomeado)</p>
                  <p style={{ fontSize: 9 }}>DDP – Delivered Duty Paid – Entregue Com Direitos Pagos (local de destino nomeado)</p>

                  <a style={{ fontSize: 12 }} href="https://www.fazcomex.com.br/blog/incoterms-2020-todos-termos/">
                    https://www.fazcomex.com.br/blog/incoterms-2020-todos-termos/
                  </a>
                </div>
                <img src={Portos} alt="porto" />
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  Spaces: {
    width: '30%',
  },

  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    borderBottom: 'none',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PrecoPorto);
