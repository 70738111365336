/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../menuGlobal';
import api from '../../services/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AmrLogo from '../../assets/AMR horizontal.png';
import isSuper from '../../utils/utils.js'

import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

const drawerWidth = 260;

function Usability(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [loginCount, setLoginCount] = useState([]);
  const [dados, setDados] = useState('');

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (((props.token?.token === undefined) & (window.localStorage.getItem('token') === null) )|(isSuper() !== true)) {
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      const response = await api.get('/returnLogin');
      setLoginCount(response.data);
      
      retornaDados()
    }
    fetchMyAPI();
  }, []);


  async function retornaDados() {
    const params = {
      ID: 0,
    };
    const responsedados = await api.get('/dadoscontroletrafego', { params });

    setDados(responsedados.data);
    console.log(responsedados.data)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Utilização da Ferramenta
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <LineChart
              width={500}
              height={300}
              data={loginCount}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="data" />
              <YAxis dataKey="logins" />
              <Tooltip coordinate={'logins'} />
              <Line type="monotone" dataKey="logins" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </div>
          <div>
            <h1>Controle de Utilização da Plataforma</h1>
          </div>
          {dados !== '' ?
          <div>
            <table style={{ border: '1px solid #FF0000' }}>
              <thead>
                <tr style={{ border: '1px solid #FF0000' }}>
                  <th style={{ border: '1px solid #FF0000', width: 200 }}>Usuário</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Janeiro</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Fevereiro</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Março</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Abril</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Maio</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Junho</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Julho</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Agosto</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Setembro</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Outubro</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Novembro</th>
                  <th style={{ border: '1px solid #FF0000', width: 100 }}>Dezembro</th>


                </tr>
              </thead>
              <tbody>
                {Object.keys(dados).map((result, index) => (
                  <tr style={{ border: '1px solid #FF0000' }}>
                    <td style={{ border: '1px solid #FF0000' }}>{dados[index].login}</td>
                    
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Janeiro']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Fevereiro']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Março']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Abril']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Maio']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Junho']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Julho']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Agosto']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Setembro']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Outubro']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Novembro']}</td>
                    <td style={{ border: '1px solid #FF0000', textAlign: 'center' }}>{dados[index]['Dezembro']}</td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
           :null}
        </Container>
        
      </main>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Usability);
