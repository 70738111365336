const initialState = {
  token: window.localStorage.getItem('token') === null ? '' : window.localStorage.getItem('token') === null,
};

export default function tokenReducer(state = initialState, action) {
  switch (action.type) {
    case 'setToken':
      window.localStorage.setItem('token', action.payload);
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
}
