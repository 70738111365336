import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import api from '../services/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setToken } from '../flux/actions/tokenIndex';
import { setUser } from '../flux/actions/userIndex';
import AmrLogo from '../assets/AMR horizontal.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © amrbi'}
      <Link color="inherit" href="https://amrbi.com.br/"></Link> {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function storeUser(props, data, login) {
  console.log(data);
  window.localStorage.setItem('user_data', JSON.stringify(data));
  props.setToken(data.token);
  props.setUser(login);
}

function SignInSide(props) {
  const [Login, setLogin] = useState('');
  const [Pass, setPass] = useState('');
  const [open, setOpen] = React.useState(false);
  const [email,setEmail] = useState('')

  const classes = useStyles();
  async function validateLogin() {
    const response = await api.get('/validadeUser', {
      params: { Login, Pass },
    });

    if ((response.data?.active || 0) === 0) {
      alert('senha inválida');
    } else if (response.data?.super === 1) {
      props.history.push({ pathname: '/adminpage' });
      storeUser(props, response.data, Login);
    } else {
      props.history.push({ pathname: '/devFormulas' });
      storeUser(props, response.data, Login);
    }
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const validateLogin = () => {
  //   if ((Login === "TesteAdm") & (Pass === "TesteAdm")) {
  //     console.log(props.history.push({ pathname: "/adminpage" }));
  //   } else alert("Senha Inválida");
  // };
  async function recuperar_senha() {
    console.log(email)
   
    const response = await api.get('/enviar_email_recupera_senha', {
      params: { email },
    });

    if (response.data === 'ONK') {
      alert('Email inválido!');
    } else if (response.data === 'OK') {
      alert('Sua senha foi enviada para o email informado!')
    }
    handleClose()
  }
  
  return (
    <Grid container component="main" className={classes.root}>
      {/* <img src={BackGroundAMRBI} alt="Logo" style={{position:'absolute',height:'100%',width:'60%'}}/> */}
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={5} md={12} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={AmrLogo} alt="Logo" style={{ height: '30%', width: '30%' }} />

          <Typography component="h1" variant="h5">
            LogIn
          </Typography>
          <form className={classes.form} noValidate>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="login"
                label="Login"
                name="login"
                autoComplete="login"
                autoFocus
                onChange={(event) => setLogin(event.target.value)}
                style={{ width: '25%' }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => setPass(event.target.value)}
                style={{ width: '25%' }}
              />

              <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
              <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={validateLogin} style={{ width: '25%' }}>
                Logar
              </Button>

              <Button className={classes.submit} onClick={handleClickOpen} style={{ width: '25%' }}>
                Recuperar Senha
              </Button>
              <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Recuperação de Senha</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Insira o email cadastrado na plataforma que enviaremos sua senha para seu email
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    onChange = {(evt) => setEmail(evt.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={() => recuperar_senha()} color="primary">
                    Enviar
                  </Button>
                </DialogActions>
              </Dialog>
              <Grid container></Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </div>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: '../../public/logo192.png',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInSide);
