import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../menuGlobal';
import TextField from '@material-ui/core/TextField';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import api from '../../services/api';
import AmrLogo from '../../assets/AMR horizontal.png';
import isSuper from '../../utils/utils.js'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}useEffect
    </Typography>
  );
}

const drawerWidth = 260;

function AdminPage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [loginNew, setLoginNew] = React.useState('');
  const [senhaNew, setSenhaNew] = React.useState('');
  const [emailNew, setEmailNew] = React.useState('');
  const [cellPhoneNew, setCellPhoneNew] = React.useState('');

  useEffect(() => {
    if (((props.token?.token === undefined) & (window.localStorage.getItem('token') === null) )|(isSuper() !== true)) {
      console.log(props.token?.token === undefined ,window.localStorage.getItem('token') === null,isSuper())
      props.history.push({ pathname: '/' });
    }
  }, [props.history, props.token]);

  async function registerUser() {
    console.log(loginNew, senhaNew, emailNew, cellPhoneNew);
    const response = await api.get('/registerUser', {
      params: { loginNew, senhaNew, emailNew, cellPhoneNew },
    });
    if (response.data === 'OK') {
      alert('Usuário cadastrado com sucesso!');
    } else {
      alert('Problemas ao cadastrar usuário!');
    }
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Basis
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <h1> Cadastrar Usuário</h1>

            <div>
              <TextField label="Login" id="Login" onChange={(evt) => setLoginNew(evt.target.value)} />
            </div>
            <div>
              <TextField label="Senha" id="Senha" onChange={(evt) => setSenhaNew(evt.target.value)} />
            </div>
            <div>
              <TextField label="Email" id="Email" onChange={(evt) => setEmailNew(evt.target.value)} />
            </div>
            <div>
              <TextField label="CellPhone" id="CellPhone" onChange={(evt) => setCellPhoneNew(evt.target.value)} />
            </div>
          </div>
          <div>
            <button style={{ height: '10%', width: '30%' }} onClick={() => registerUser()}>
              Cadastra Usuário
            </button>
          </div>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
