const initialState = {
    user:  window.localStorage.getItem('user') === null ? '' : window.localStorage.getItem('user') ,
  };
  
  export default function userReducer(state = initialState, action) {
    switch (action.type) {
      case "setUser":
        
        window.localStorage.setItem('user',action.payload)
        return {
          ...state,
          user: action.payload,
        };
      default:
        return state;
    }
  }
  