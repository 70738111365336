import React, { useState } from 'react';
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';


function isSuper(){
  try {    
    return JSON.parse(window.localStorage.getItem('user_data'))?.super === 1;
  } catch (error) {
      return false;   
  }
}

function createComponent(path, title){
  return (
    <Link to={ path } style={{ textDecoration: "none" }}>
      <div
        style={{
          display: "flex",
          marginTop: 10,
          marginBottom: 20,
          flexDirection: "column",
        }}
      >
        <b style={{ color: "#404040", fontSize: 16 }}>
          { title }
        </b>
        <div
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "lightgrey",
            borderBottomStyle: "solid",
            marginTop: 10,
            width: "85%",
          }}
        ></div>
      </div>
    </Link>
  )
}

function createAdmComponent(path, title){
  return (    
    <ListItem component={Link} to={path} style={{ color: "rgba(0, 0, 0, 0.94)",  paddingLeft: 0 }}>
        <b style={{ color: "#404040", fontSize: 12 }}>
          { title }
        </b>               
    </ListItem>      
  )
}

export default function render() {
  const _isSuper = isSuper();  

  return (    

     <div style={{ marginLeft: 15, marginTop: 25 }}>
    { createComponent("/dolarsojamilho", "Histórico de Commodities") }
    { createComponent("/preficacaoPorto", "Precificação Porto") }
    { createComponent("/devFormulas", "Retorna Formulas") }
    { createComponent("/relacaodetroca", "Simulador Relacão De Troca") }
    { createComponent("/historicorelacaodetroca", "Historico Relacão De Troca") }
    { createComponent("/basis", "Basis Soja") }
    { createComponent("/basisFert", "Basis Fertilizantes") }
    { createComponent("/images", "Previsão Climatica") }

    { _isSuper ?
      <List subheader={<ListSubheader>Administrador</ListSubheader>}>
          { createAdmComponent("/adminpage", "Cadastrar Usuários")}
          { createAdmComponent("/registered", "Usuários Cadastrados")}
          { createAdmComponent("/utilizacao", "Utilização da ferramenta") }
          { createAdmComponent("/configMaterias", "Configurações de matérias prima") }
          { createAdmComponent("/configPortos", "Configurações dos portos")}
          { createAdmComponent("/configCompat", "Configurações de Compatibilidade")}
          { createAdmComponent("/configFormulasPadrao", "Configurações de fechamentos")}
          { createAdmComponent("/configfretes", "Configurações Relaçao de Troca")}
          { createAdmComponent("/histRdt", "Dados Histórico Relação de troca") }
          { createAdmComponent("/configfreteshistorico", "Configurações históricos de fretes")}
          { createAdmComponent("/controleutilizacao", "Controle de Utilização") }
      </List>
    : console.log('skip')
    }
    
         
    </div>
  );  
}


export const mainListItems = render();
