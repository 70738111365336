import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isSuper from '../../../utils/utils.js'

import api from '../../../services/api';
import AmrLogo from '../../../assets/AMR horizontal.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 260;

function ShowDataHistRdt(props) {
  const [imageUrMilho, setImageUrMilho] = useState('');
  const [imageKCLMilho, setImageKCLMilho] = useState('');
  const [imageMAPMilho, setImageMAPMilho] = useState('');
  const [imageSSPSoja, setImageSSPSoja] = useState('');
  const [imageKCLSoja, setImageKCLSoja] = useState('');
  const [imageMAPSoja, setImageMAPSoja] = useState('');
  const [toUpdate, setToUpdate] = useState([]);
  //   const [imageUrMilhobar, setImageUrMilhobar] = useState("");
  //   const [imageKCLMilhobar, setImageKCLMilhobar] = useState("");
  //   const [imageMAPMilhobar, setImageMAPMilhobar] = useState("");
  //   const [imageSSPSojabar, setImageSSPSojabar] = useState("");
  //   const [imageKCLSojabar, setImageKCLSojabar] = useState("");
  //   const [imageMAPSojabar, setImageMAPSojabar] = useState("");

  const [dados, setDados] = useState(false);
  useEffect(() => {
    if (((props.token?.token === undefined) & (window.localStorage.getItem('token') === null) )|(isSuper() !== true)) {
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      const response = await api.get('/returnRelacaoDeTrocaTeste', {
        params: {
          materia: 'UR',
          tipo: 'milho',
          dados,
          bar: 'false',
          adm: 'true',
        },
      });
      setImageUrMilho(response.data);
      const responseKCL = await api.get('/returnRelacaoDeTrocaTeste', {
        params: {
          materia: 'KCL',
          tipo: 'milho',
          dados,
          bar: 'false',
          adm: 'true',
        },
      });
      setImageKCLMilho(responseKCL.data);
      const responseMAP = await api.get('/returnRelacaoDeTrocaTeste', {
        params: {
          materia: 'MAP 52',
          tipo: 'milho',
          dados,
          bar: 'false',
          adm: 'true',
        },
      });
      setImageMAPMilho(responseMAP.data);
      const responseMAPSo = await api.get('/returnRelacaoDeTrocaTeste', {
        params: {
          materia: 'MAP 52',
          tipo: 'soja',
          dados,
          bar: 'false',
          adm: 'true',
        },
      });
      setImageMAPSoja(responseMAPSo.data);
      const responseKCLSo = await api.get('/returnRelacaoDeTrocaTeste', {
        params: {
          materia: 'KCL',
          tipo: 'soja',
          dados,
          bar: 'false',
          adm: 'true',
        },
      });
      setImageKCLSoja(responseKCLSo.data);
      const responseSSP = await api.get('/returnRelacaoDeTrocaTeste', {
        params: {
          materia: 'SSP 21',
          tipo: 'soja',
          dados,
          bar: 'false',
          adm: 'true',
        },
      });
      setImageSSPSoja(responseSSP.data);
    }
    fetchMyAPI();
  }, [dados, props.history, props.token]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function sendPriceChange() {
    let auxComp = '';

    Object.keys(toUpdate).forEach((item) => (auxComp = auxComp + item + '#' + toUpdate[item] + ';'));
    const response_update = await api.get('/alteracoesHistRelTroca', {
      params: {
        dados: auxComp,
      },
    });
    if (response_update === 'OK') {
      alert('Dados Atualizados com sucesso!');
      window.location.reload();
    } else {
      alert('Erro ao atualizar dados');
    }
  }

  const hadlePriceChange = (ano, mes, materia, cultura, valor) => {
    let aux = toUpdate;
    aux[mes + '*' + ano + '*' + materia + '*' + cultura] = valor;
    setToUpdate(aux);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Histórico de Relacao de troca
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <h2> Dados KCL Soja</h2>
                  {imageKCLSoja !== '' ? (
                    <table
                      style={{
                        borderCollapse: 'collapse',
                        borderSpacing: 0,
                      }}
                    >
                      <thead style={{ height: 50 }}>
                        <tr>
                          <th style={{ width: 200, textAlign: 'center' }}>Ano</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Janeiro</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Fevereiro</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Março</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Abril</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Maio</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Junho</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Julho</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Agosto</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Setembro</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Outubro</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Novembro</th>
                          <th style={{ width: 200, textAlign: 'center' }}>Dezembro</th>
                        </tr>
                      </thead>

                      <tbody>
                        {Object.keys(imageKCLSoja).map((item, index) => (
                          <tr
                            key={index}
                            style={{
                              background: index % 2 === 0 ? 'lightgray' : 'white',
                              height: 50,
                            }}
                          >
                            <td style={{ width: 200, textAlign: 'center' }}>{item}</td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 1, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][1]} />
                            </td>

                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 2, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][2]} />
                            </td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 3, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][3]} />
                            </td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 4, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][4]} />
                            </td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 5, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][5]} />
                            </td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 6, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][6]} />
                            </td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 7, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][7]} />
                            </td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 8, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][8]} />
                            </td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 9, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][9]} />
                            </td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 10, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][10]} />
                            </td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 11, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][11]} />
                            </td>
                            <td style={{ width: 200, textAlign: 'center' }}>
                              <TextField onChange={(e) => hadlePriceChange(item, 12, 'KCL', 'Soja', e.target.value)} variant="outlined" label={imageKCLSoja[item][12]} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <CircularProgress style={{ color: 'green' }} />
                  )}
                </div>
              </div>
              <div>
                <button onClick={() => sendPriceChange()}> Alterar Preços </button>
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <h2> Dados MAP Soja</h2>
                    {imageMAPSoja !== '' ? (
                      <table
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: 0,
                        }}
                      >
                        <thead style={{ height: 50 }}>
                          <tr>
                            <th style={{ width: 200, textAlign: 'center' }}>Ano</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Janeiro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Fevereiro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Março</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Abril</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Maio</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Junho</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Julho</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Agosto</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Setembro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Outubro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Novembro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Dezembro</th>
                          </tr>
                        </thead>

                        <tbody>
                          {Object.keys(imageMAPSoja).map((item, index) => (
                            <tr
                              key={index}
                              style={{
                                background: index % 2 === 0 ? 'lightgray' : 'white',
                                height: 50,
                              }}
                            >
                              <td style={{ width: 200, textAlign: 'center' }}>{item}</td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 1, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][1]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 2, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][2]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 3, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][3]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 4, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][4]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 5, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][5]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 6, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][6]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 7, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][7]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 8, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][8]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 9, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][9]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 10, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][10]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 11, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][11]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 12, 'MAP 52', 'Soja', e.target.value)} variant="outlined" label={imageMAPSoja[item][12]} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <h2> Dados SSP Soja</h2>
                    {imageSSPSoja !== '' ? (
                      <table
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: 0,
                        }}
                      >
                        <thead style={{ height: 50 }}>
                          <tr>
                            <th style={{ width: 200, textAlign: 'center' }}>Ano</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Janeiro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Fevereiro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Março</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Abril</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Maio</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Junho</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Julho</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Agosto</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Setembro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Outubro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Novembro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Dezembro</th>
                          </tr>
                        </thead>

                        <tbody>
                          {Object.keys(imageSSPSoja).map((item, index) => (
                            <tr
                              key={index}
                              style={{
                                background: index % 2 === 0 ? 'lightgray' : 'white',
                                height: 50,
                              }}
                            >
                              <td style={{ width: 200, textAlign: 'center' }}>{item}</td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 1, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][1]} />
                              </td>

                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 2, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][2]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 3, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][3]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 4, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][4]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 5, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][5]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 6, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][6]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 7, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][7]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 8, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][8]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 9, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][9]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 10, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][10]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 11, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][11]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 12, 'SSP 21', 'Soja', e.target.value)} variant="outlined" label={imageSSPSoja[item][12]} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h4> Milho</h4>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <h2> Dados UR Milho</h2>
                    {imageUrMilho !== '' ? (
                      <table
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: 0,
                        }}
                      >
                        <thead style={{ height: 50 }}>
                          <tr>
                            <th style={{ width: 200, textAlign: 'center' }}>Ano</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Janeiro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Fevereiro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Março</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Abril</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Maio</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Junho</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Julho</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Agosto</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Setembro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Outubro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Novembro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Dezembro</th>
                          </tr>
                        </thead>

                        <tbody>
                          {Object.keys(imageUrMilho).map((item, index) => (
                            <tr
                              key={index}
                              style={{
                                background: index % 2 === 0 ? 'lightgray' : 'white',
                                height: 50,
                              }}
                            >
                              <td style={{ width: 200, textAlign: 'center' }}>{item}</td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 1, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][1]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 2, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][2]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 3, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][3]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 4, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][4]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 5, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][5]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 6, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][6]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 7, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][7]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 8, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][8]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 9, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][9]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 10, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][10]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 11, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][11]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 12, 'UR', 'milho', e.target.value)} variant="outlined" label={imageUrMilho[item][12]} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <h2> Dados MAP Milho</h2>
                    {imageMAPMilho !== '' ? (
                      <table
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: 0,
                        }}
                      >
                        <thead style={{ height: 50 }}>
                          <tr>
                            <th style={{ width: 200, textAlign: 'center' }}>Ano</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Janeiro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Fevereiro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Março</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Abril</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Maio</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Junho</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Julho</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Agosto</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Setembro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Outubro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Novembro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Dezembro</th>
                          </tr>
                        </thead>

                        <tbody>
                          {Object.keys(imageMAPMilho).map((item, index) => (
                            <tr
                              key={index}
                              style={{
                                background: index % 2 === 0 ? 'lightgray' : 'white',
                                height: 50,
                              }}
                            >
                              <td style={{ width: 200, textAlign: 'center' }}>{item}</td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 1, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][1]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 2, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][2]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 3, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][3]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 4, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][4]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 5, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][5]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 6, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][6]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 7, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][7]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 8, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][8]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 9, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][9]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 10, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][10]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 11, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][11]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 12, 'MAP 52', 'milho', e.target.value)} variant="outlined" label={imageMAPMilho[item][12]} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <h2> Dados KCL Milho</h2>
                    {imageKCLMilho !== '' ? (
                      <table
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: 0,
                        }}
                      >
                        <thead style={{ height: 50 }}>
                          <tr>
                            <th style={{ width: 200, textAlign: 'center' }}>Ano</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Janeiro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Fevereiro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Março</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Abril</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Maio</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Junho</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Julho</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Agosto</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Setembro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Outubro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Novembro</th>
                            <th style={{ width: 200, textAlign: 'center' }}>Dezembro</th>
                          </tr>
                        </thead>

                        <tbody>
                          {Object.keys(imageKCLMilho).map((item, index) => (
                            <tr
                              key={index}
                              style={{
                                background: index % 2 === 0 ? 'lightgray' : 'white',
                                height: 50,
                              }}
                            >
                              <td style={{ width: 200, textAlign: 'center' }}>{item}</td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 1, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][1]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 2, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][2]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 3, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][3]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 4, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][4]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 5, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][5]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 6, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][6]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 7, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][7]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 8, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][8]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 9, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][9]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 10, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][10]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 11, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][11]} />
                              </td>
                              <td style={{ width: 200, textAlign: 'center' }}>
                                <TextField onChange={(e) => hadlePriceChange(item, 12, 'KCL', 'milho', e.target.value)} variant="outlined" label={imageKCLMilho[item][12]} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <CircularProgress style={{ color: 'green' }} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  Spaces: {
    width: '30%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowDataHistRdt);
