import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';

import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../menuGlobal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import Checkbox from "@material-ui/core/Checkbox";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import api from '../../../services/api';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import AmrLogo from '../../../assets/AMR horizontal.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'AMRBI © '}
      <Link color="inherit" href="https://amrbi.com.br/">
        AmrBI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: '100%',
          height: '100',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    )
  );
};
const drawerWidth = 260;

function RelacaoDeTroca(props) {
  // const [portos, setPortos] = useState([]);
  // const [porto, setPorto] = useState("");
  const [dolar, setDolar] = useState(0);
  const [dolarHora, setDolarHora] = useState(0);
  const [freteUsuario, setFreteUsuario] = useState(100);
  //const [checked, setChecked] = React.useState(false);
  const [precificadoPadrao, setPrecificadoPadrao] = useState({});
  const [valorSoja, setValorSoja] = useState(0);
  const [valorJuroUSD, setValorJuroUSD] = useState(1);
  const [valorJuroBR, setValorJuroBR] = useState(1.7);
  const [materias, setMaterias] = useState('');
  const [pref, setPref] = useState([]);
  const [dataVencimento, setDataVencimento] = useState('31/03/2023');
  const [nitro, setNitro] = useState(0);
  const [p2O5, setP2O5] = useState(0);
  const [k2O, setk2O] = useState(0);
  const [formulas_default, setFormulas_default] = useState('');
  const [formulas, setFormulas] = useState('');
  const [cidade, setCidade] = useState('RIO VERDE');
  const [lastUpdate, setLastUpdate] = useState('');

  useEffect(() => {
    if (props.token?.token === undefined) {
      //   alert("Você não está logado!");
      props.history.push({ pathname: '/' });
    }
    async function fetchMyAPI() {
      //  const response = await api.get("/returnPortos");
      // setPortos(response.data);
      const response_frete = await api.get('returnFreteHistoricoCidade', {
        params: {
          cidade: 'RIO VERDE',
        },
      });
      setFreteUsuario(parseFloat(response_frete.data));
      const response_materias = await api.get('/returnIndiceMateriasTroca');
      setMaterias(response_materias.data);

      const response_rioVerde = await api.get('/sojaRioVerde');
      setValorSoja(response_rioVerde.data);

      const respose_dolar_hora = await api.get('/DolarApi');
      setDolarHora(respose_dolar_hora.data);
      const response_precificado_padrao = await api.get('/returnRelacaoDeTroca', {
        params: {
          dolar: 0,
          porto: '',
          valorSoja: valorSoja,
          freteFertilizantes: freteUsuario,
          cidade: cidade,
          valorJuroUSD: valorJuroUSD,
          valorJuroBR: valorJuroBR,
          pref: '[]',
          data: dataVencimento,
        },
      });
      setPrecificadoPadrao(response_precificado_padrao.data);
      const respose_update = await api.get('/returnLastUpdateMaterias');
      setLastUpdate(respose_update.data);
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  async function getRelacaoUsuario() {
    try {
      const user = JSON.parse(window.localStorage.getItem('user_data'));
      await api.post('/controletrafego', { user: user.ID, path: 'Relação de Troca' });
    } catch (error) {
      console.log('controle de trafego não registrado');
    }

    let auxPref = [];
    pref.forEach((index) => (auxPref = [...auxPref, index.abrev]));
    const response_precificado_padrao = await api.get('/returnRelacaoDeTroca', {
      params: {
        dolar: dolar,
        porto: '',
        valorSoja: valorSoja,
        freteFertilizantes: freteUsuario,
        cidade: cidade,
        valorJuroUSD: valorJuroUSD,
        valorJuroBR: valorJuroBR,
        pref: auxPref.toString(),
        data: dataVencimento,
      },
    });
    setPrecificadoPadrao(response_precificado_padrao.data);
    if (!(nitro === 0 && p2O5 === 0 && k2O === 0)) {
      getFormulas();
    }
  }
  async function getFormulas() {
    const response_formulas_troca = await api.get('returnFormulasDefaultTroca', {
      params: {
        nitrogenio: nitro,
        p2o5: p2O5,
        k2o: k2O,
        porto: '',
        dolar: dolar,
        valorSoja: valorSoja,
        freteFertilizantes: freteUsuario,
        cidade: cidade,
        valorJuroUSD: valorJuroUSD,
        valorJuroBR: valorJuroBR,
        data: dataVencimento,
      },
    });
    const response_formulas_default = await api.get('returnFormulasDefault', {
      params: {
        nitrogenio: nitro,
        p2o5: p2O5,
        k2o: k2O,
        porto: 'Paranaguá/PR',
        frete: 0,
        dolar: 0,
      },
    });

    setFormulas_default('');

    if (response_formulas_troca.data !== 'NOK') {
      setFormulas(response_formulas_default.data);
      setFormulas_default(response_formulas_troca.data);
    } else {
      alert('Não existem fórmulas pré calculadas com esse fechamento!');
    }
  }

  const resetTable = (e, v) => {
    setPrecificadoPadrao('');
    setFormulas_default('');
    setPref(v);
  };

  async function getFretesCidades(v) {
    const response_frete = await api.get('returnFreteHistoricoCidade', {
      params: {
        cidade: v,
      },
    });
    setFreteUsuario(parseFloat(response_frete.data));
    setCidade(v);
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} style={{ color: '#000000' }}>
            Relação de Troca
          </Typography>
          <IconButton edge="start" color="primary" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={AmrLogo} alt="Logo" style={{ position: 'absolute', height: '30%', width: '120%' }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ marginBottom: 20 }}>
            <h1> Relação de Troca</h1>
            <h3>
              Último Update no valor das máterias primas :<strong> {lastUpdate.split('-')[2] + '/' + lastUpdate.split('-')[1] + '/' + lastUpdate.split('-')[0]}</strong>
            </h3>

            <p>Dolar hora : {dolarHora}</p>
            {/* <div style={{ display: "flex", flexDirection: "row" }}>
              <h4>Simular Valores de para relação de troca com seus dados</h4>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Autocomplete
                id="multiple-limit-tags"
                options={[{ name: 'RIO VERDE' }, { name: 'Rondonópolis' }]}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => getFretesCidades(v.name)}
                style={{ width: 500, marginTop: 10 }}
                label={cidade}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Cidade" placeholder="Cidade" />}
              />
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <h4 style={{ width: 150 }}>Dolar : </h4>
                <TextField variant="outlined" label={dolarHora} id="Dolar" onChange={(evt) => setDolar(evt.target.value)} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <h4 style={{ width: 150 }}>Frete Fertilizantes : </h4>
                <TextField variant="outlined" label={freteUsuario} id="Frete" onChange={(evt) => setFreteUsuario(evt.target.value)} />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <h4 style={{ width: 150 }}>Valor Soja: </h4>
                <TextField variant="outlined" label={valorSoja} id="Valor Soja" onChange={(evt) => setValorSoja(evt.target.value)} />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <h4 style={{ width: 150 }}>Juro USD </h4>
                <TextField variant="outlined" label={valorJuroUSD} id="Juro USD" onChange={(evt) => setValorJuroUSD(evt.target.value)} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <h4 style={{ width: 150 }}>Juro BR </h4>
                <TextField variant="outlined" label={valorJuroBR} id="Juro BR" onChange={(evt) => setValorJuroBR(evt.target.value)} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <h4 style={{ width: 150 }}>Data </h4>
                <TextField variant="outlined" label={dataVencimento} id="vencimento" onChange={(evt) => setDataVencimento(evt.target.value)} />
              </div>
              <div>
                <div>
                  <TextField
                    variant="outlined"
                    label="N"
                    id="N"
                    onChange={(evt) => setNitro(evt.target.value)}
                    inputProps={{ style: { fontSize: 15 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                    style={{ width: 100 }}
                  />

                  <TextField
                    variant="outlined"
                    label="P2O5"
                    id="P2O5"
                    onChange={(evt) => setP2O5(evt.target.value)}
                    inputProps={{ style: { fontSize: 15 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                    style={{ width: 100, marginLeft: 15 }}
                  />
                  <TextField
                    variant="outlined"
                    label="K20"
                    id="K20"
                    onChange={(evt) => setk2O(evt.target.value)}
                    inputProps={{ style: { fontSize: 15 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                    style={{ width: 100, marginLeft: 15 }}
                  />
                </div>
              </div>
              {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  Porto
                  <Autocomplete
                    id="combo-box-demo"
                    options={portos}
                    getOptionLabel={(option) => option.porto}
                    style={{ width: 300 }}
                    onInputChange={(e, v) => setPorto(v)}
                    renderInput={(params) => (
                      <TextField {...params} label="Porto" variant="outlined" />
                    )}
                  />
                </div> */}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Autocomplete
                multiple
                id="multiple-limit-tags"
                options={materias}
                getOptionLabel={(option) => option.abrev}
                onChange={(e, v) => resetTable(e, v)}
                style={{ width: 500, marginTop: 10 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Matérias para vizualização da relação de troca" placeholder="Matérias para vizualização da relação de troca" />
                )}
              />
            </div>
            <button
              style={{
                marginTop: 20,
                height: 50,
                width: 250,
                borderRadius: 20,
                borderStyle: 'solid',
                background: 'linear-gradient(to right, #a9d046, #d3e05c)',
              }}
              onClick={() => trackPromise(getRelacaoUsuario())}
            >
              <p
                style={{
                  marginTop: 13,
                  fontStyle: 'bold',
                  color: 'white',
                  fontSize: 16,
                }}
              >
                Pesquisar
              </p>
            </button>
          </div>
          {/* <div>
            <h4>Soja Futuro Rio Verde (ifag Março) : {sojaRioVerde} R$</h4>
            <h4>Soja Futuro Rondonópolis (imea Março) : {sojaRondo} R$</h4>
            <h4>Frete Fertilizantes Rondonópolis : {freteUsuarioRondo} R$</h4>
            <h4>Frete Fertilizantes Rio Verde : {freteUsuarioRio} R$</h4>
          </div> */}
          <LoadingIndicator />
          {formulas_default !== '' ? (
            <div>
              {console.log(formulas)}
              <h2>
                Fechamento {nitro} - {p2O5}- {k2O}
              </h2>
              <h3>{cidade}</h3>
              {Object.keys(formulas).map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    borderWidth: '0.5px',
                    border: 'solid',
                    borderRadius: 30,
                    flexDirection: 'column',
                    marginBottom: 15,
                    borderColor: 'lightgrey',
                    height: 400,
                    width: 700,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',

                      flexDirection: 'row',
                      marginBottom: 10,
                      marginLeft: 20,
                    }}
                  >
                    {Object.keys(formulas[item]).map((item_, index_) =>
                      !item_.includes('price') & !item_.includes('nutri') ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 100,
                            marginTop: 19,
                          }}
                        >
                          <div
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                            }}
                          >
                            <p>{item_}</p>
                          </div>
                          <div style={{ height: '50px', textAlign: 'center' }}>
                            <p>{formulas[item][item_]} KG</p>
                          </div>
                          <div
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                            }}
                          >
                            <p>{formulas[item][item_] / 10} %</p>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',

                      flexDirection: 'row',
                      marginBottom: 30,
                      height: '50px',
                      marginLeft: 20,
                    }}
                  >
                    {Object.keys(formulas_default[0]).map((item_, index_) =>
                      item_.includes('total') && !item_.includes('sc') ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 150,
                          }}
                        >
                          <div style={{ height: '50px', textAlign: 'center' }}>
                            {' '}
                            <p>{item_}</p>
                          </div>{' '}
                          <div
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                            }}
                          >
                            <p>{formulas_default[0][item_]} </p>
                          </div>
                        </div>
                      ) : (
                        console.log('nada')
                      )
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: 20,
                      flexDirection: 'row',
                      marginTop: 20,
                    }}
                  >
                    {Object.keys(formulas_default[0]).map((item_, index_) =>
                      item_.includes('total') && item_.includes('sc') && !item_.includes('total_sc_ton_RS') && !item_.includes('total_sc_ton_US') ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 150,
                          }}
                        >
                          <div style={{ height: '50px', textAlign: 'center' }}>
                            {' '}
                            <p>{item_}</p>
                          </div>{' '}
                          <div
                            style={{
                              height: '50px',
                              textAlign: 'center',
                              backgroundColor: '#E0FFFF',
                            }}
                          >
                            <p>{formulas_default[0][item_]} </p>
                          </div>
                        </div>
                      ) : (
                        console.log('nada2')
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div></div>
          )}
          {precificadoPadrao !== '' ? (
            <table
              style={{
                border: '1px solid lightgray',
                width: 900,
                borderCollapse: 'collapse',
                borderSpacing: 0,
                borderRadius: 20,
                padding: 10,
              }}
            >
              <thead>
                <tr style={{ background: 'lightgray', height: 50 }}>
                  <th style={{ width: 200, textAlign: 'center' }}>Porto</th>
                  <th style={{ width: 200, textAlign: 'center' }}>Cidade</th>
                  <th style={{ width: 200, textAlign: 'center' }}>Materia</th>
                  <th style={{ width: 200, textAlign: 'center' }}>R$ TON</th>
                  <th style={{ width: 200, textAlign: 'center' }}>U$ TON</th>
                  <th style={{ width: 200, textAlign: 'center' }}>{'R$ Ton Juros ' + dataVencimento}</th>
                  <th style={{ width: 200, textAlign: 'center' }}>{'U$ Ton Juros ' + dataVencimento}</th>

                  <th style={{ width: 200, textAlign: 'center' }}>SC Ton Juros R$</th>
                  <th style={{ width: 200, textAlign: 'center' }}>SC Ton Juros U$</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(precificadoPadrao).map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      background: index % 2 !== 0 ? 'lightgray' : 'white',
                      height: 50,
                    }}
                  >
                    <td style={{ width: 200, textAlign: 'center' }}>{precificadoPadrao[item].porto}</td>
                    <td style={{ width: 200, textAlign: 'center' }}>{precificadoPadrao[item].cidade}</td>
                    <td style={{ width: 200, textAlign: 'center' }}>{precificadoPadrao[item].materia}</td>
                    <td style={{ width: 200, textAlign: 'center' }}>{precificadoPadrao[item].RS_ton}</td>
                    <td style={{ width: 200, textAlign: 'center' }}>{precificadoPadrao[item].US_ton}</td>
                    <td style={{ width: 200, textAlign: 'center' }}>{precificadoPadrao[item].RS_ton_juros}</td>
                    <td style={{ width: 200, textAlign: 'center' }}>{precificadoPadrao[item].US_ton_juros}</td>
                    <td style={{ width: 200, textAlign: 'center' }}>{precificadoPadrao[item].sc_ton_juros_rs}</td>
                    <td style={{ width: 200, textAlign: 'center' }}>{precificadoPadrao[item].sc_ton_juros_usd}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div></div>
          )}

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  Spaces: {
    width: '30%',
  },

  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    borderBottom: 'none',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function mapStateToProps(state) {
  return {
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RelacaoDeTroca);
