import React from 'react';
import ReactDOM from 'react-dom';
import SignInSide from '../src/siginIn/SignInSide';
import AdminPage from '../src/pages/adminPage/AdminPage';
import RegisteredUsers from '../src/pages/adminPage/RegisteredUsers';
import Usability from '../src/pages/adminPage/Usability';
import ConfigMaterias from '../src/pages/adminPage/ConfigMaterias/ConfigMaterias';
import ConfigPortos from '../src/pages/adminPage/ConfigPortos/ConfigPortos';
import ConfigCompat from '../src/pages/adminPage/ConfigCompat/ConfigCompat';
import ConfigFormulasPadrao from '../src/pages/adminPage/ConfigFormulasPadrao/ConfigFormulasPadrao';
import ConfigFretes from '../src/pages/adminPage/ConfigFretes/ConfigFretes';
import ConfigFretesHistorico from '../src/pages/adminPage/ConfigFretesHistorico/ConfigFretesHistorico';
import ControleUtilizacao from '../src/pages/adminPage/ControleUtilizacao/ControleUtilizacao';
import ShowDataHistRdt from '../src/pages/adminPage/ShowDataHistRdt/ShowDataHistRdt';
import Images from '../src/pages/usersPage/Images/Images';

//import UsersPage from "../src/pages/usersPage/UsersPage";
import DolarMilhoSoja from '../src/pages/usersPage/Dolar_milho_soja';
import PrecoPorto from '../src/pages/usersPage/PrecoPorto/PrecoPorto';
import DevFormulas from '../src/pages/usersPage/DevFormulas/Formulas';
import Config from '../src/pages/usersPage/Config/Config';
import RelacaoDeTroca from '../src/pages/usersPage/RelacaoDeTroca/RelacaoDeTroca';
import Basis from '../src/pages/usersPage/Basis/Basis';
import HistoricoRelTroca from '../src/pages/usersPage/HistoricoRelTroca/HistoricoRelTroca';
import BasisFert from '../src/pages/usersPage/BasisFert/BasisFert';
//import Dashboard from '../src/dashboard/Dashboard'
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import allReducers from './flux/reducers/index';

const store = createStore(allReducers);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={SignInSide} />

        <Route path="/adminpage" component={AdminPage} />
        <Route path="/registered" component={RegisteredUsers} />
        <Route path="/utilizacao" component={Usability} />
        <Route path="/configMaterias" component={ConfigMaterias} />
        <Route path="/configPortos" component={ConfigPortos} />
        <Route path="/configCompat" component={ConfigCompat} />
        <Route path="/configFormulasPadrao" component={ConfigFormulasPadrao} />
        <Route path="/configfretes" component={ConfigFretes} />
        <Route path="/basisFert" component={BasisFert} />
        <Route path="/configfreteshistorico" component={ConfigFretesHistorico} />
        <Route path="/controleutilizacao" component={ControleUtilizacao} />

        <Route path="/histRdt" component={ShowDataHistRdt} />
        {/* <Route path="/userspage" component={UsersPage} /> */}
        <Route path="/dolarsojamilho" component={DolarMilhoSoja} />
        <Route path="/preficacaoPorto" component={PrecoPorto} />
        <Route path="/devFormulas" component={DevFormulas} />
        <Route path="/config" component={Config} />
        <Route path="/relacaodetroca" component={RelacaoDeTroca} />
        <Route path="/historicorelacaodetroca" component={HistoricoRelTroca} />
        <Route path="/images" component={Images} />

        <Route path="/basis" component={Basis} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
